import { mapboxgl } from "../configs/mapbox";
import { getCount } from "./getMarkersCount";
import { createClusterElement } from "./createClusterElement";
import { IMG_MODES } from "../constants";

 /**
   * This function updates clusters when User interacting  (Zoom/Pan)
   * @param {object} supercluster  supercluster object for geospatial point clustering
   */
export function updateClusters(
  supercluster,
  map,
  markersRef,
  uniqueMarkerData,
  fetchedData,
  setUniqueMarkerData
) {
  var zoom = map?.current?.getZoom();
  var clusters = supercluster?.getClusters([-180, -90, 180, 90], ~~zoom);
  // Clear existing markers from the map
  let markersCount;
  markersRef.current.forEach(function (marker) {
    marker.remove();
  });
  // Add the clustered markers to the map
  if (fetchedData.length && Object.keys(uniqueMarkerData).length) {
    clusters?.forEach(function (cluster, index) {
      if (cluster?.properties && cluster?.properties.cluster) {
        markersCount = getCount(
          cluster.id,
          supercluster,
          uniqueMarkerData,
          setUniqueMarkerData,
          map
        );
        if (markersCount) {
          var clusterMarker = new mapboxgl.Marker({
            element: createClusterElement(
              map,
              markersCount,
              cluster.properties.cluster_id,
              cluster.geometry,
              cluster
            ),
          })
            .setLngLat(cluster.geometry.coordinates)
            ?.addTo(map.current);
          markersRef.current.push(clusterMarker);
        }
      } else {
        var markerId = cluster.properties.marker_id;
        var marker = markersRef.current[markerId];
        if (marker.markerData) {
          if (uniqueMarkerData.hasOwnProperty(marker.markerData.location)) {
            if (uniqueMarkerData[marker.markerData.location].length > 1) {
              if (marker._element.classList.contains("white-marker")) {
                marker._element.classList.remove("white-marker");
                marker._element.classList.add("empty-marker");
              } else if (marker._element.classList.contains("purple-marker")) {
                marker._element.classList.remove("purple-marker");
                marker._element.classList.add("empty-marker");
              } else if (marker._element.classList.contains("yellow-marker")) {
                marker._element.classList.remove("yellow-marker");
                marker._element.classList.add("empty-marker");
              }
              marker._element.classList.add("empty-marker");
              let rotation =
                marker.markerData.capturedImageMode === IMG_MODES.panorama &&
                marker?.markerData.panoramaImages?.length > 1
                  ? marker?.markerData.panoramaImages[1]?.heading
                  : marker.markerData.heading
                  ? marker.markerData.heading
                  : 0;
              marker._element.innerHTML = `<div style="position: relative;
                display: flex;
                ">
                <p 
                className = "cluster-text"
                style="margin:auto;
                line-height:1;
                font-size:14px;
                transform:rotate(${360 - rotation}deg);
                padding-right:
                ${
                  (uniqueMarkerData[marker.markerData.location].length > 11 &&
                    uniqueMarkerData[marker.markerData.location].length < 20) ||
                  uniqueMarkerData[marker.markerData.location].length === 10
                    ? "1px"
                    : "0px"
                };
                padding-left:
                ${
                  uniqueMarkerData[marker.markerData.location].length % 10 ===
                    1 &&
                  uniqueMarkerData[marker.markerData.location].length !== 11
                    ? "1px"
                    : "0px"
                }
                ">
                ${uniqueMarkerData[marker.markerData.location].length}
                </p>
                </div>`;
            }
            marker?.addTo(map.current);
          }
        }
      }
    });
  }
}
