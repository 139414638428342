// Import necessary Firebase functions
import "firebase/firestore";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "@firebase/auth";
import { setPersistence, browserSessionPersistence } from "@firebase/auth";
/**
 * Using for Staging/Production
 * Initialize Firebase app with the provided configuration
 */
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};
// Initialize Firebase DB instance
export const db = initializeApp(firebaseConfig, "app1"); 
export const firestore = getFirestore(db);
// Initialize Firestore for database functionality
export const auth = getAuth(db);
/**
 * Set the persistence type for authentication sessions
 * Using browserSessionPersistence means the user will stay logged in only for the duration of the session
(i.e., until the browser tab is closed)
 */
setPersistence(auth, browserSessionPersistence);
