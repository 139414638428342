import React, { useState } from "react";
import { DateRangePicker, defaultStaticRanges } from "react-date-range";
import moment from "moment";
import { isSameDay, parseISO } from "date-fns";
import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";

/**
 * @param {function} props.setEndDate The setter function for end date.
 * @param {function} props.setStartDate The setter function for start date.
 * @param {string} props.setModalShow The setter function for date range modal view.
 * @param {string} props.startDate The start date from date filter.
 * @param {string} props.endDate The end date from date filter.
 * @param {string} props.modalShow The boolean value for modal view.
 * @returns 
 */
const ModalComponent = (props) => {
  const [localstartDate, setLocalStartDate] = useState(props.startDate);
  const [localendDate, setLocalEndDate] = useState(props.endDate);
  const [currentYear] = useState(new Date().getFullYear());
  const [currentState, setCurrentState] = useState([
    {
      startDate: parseISO(props?.startDate),
      endDate: parseISO(props?.endDate),
      key: "selection",
    },
  ]);
  /**
   * Takes selected datetime for start and end date and sets local startDate and endDate
   * @param {Object} item Object having start and end date which user has selected
   */
  const handleChangeDateRange = (item) => {
    setCurrentState([item.selection]);
    const formattedStartDate = moment(item.selection.startDate).format("YYYY-MM-DDTHH:mm:ss.SSSS[Z]")
    const formattedEndDate = moment(item.selection.endDate).format("YYYY-MM-DDTHH:mm:ss.SSSS[Z]")
    setLocalStartDate(formattedStartDate);
    setLocalEndDate(formattedEndDate);
  };
  /**
   * Handles saving daterange in props func. which are further used in mainpage component
   */
  const handleSumbitDateRange = () => {
    props.setStartDate(localstartDate);
    props.setEndDate(localendDate);
    props.setModalShow(false);
  };
  return (
    <Modal
      show={props.modalShow}
      onHide={() => props.setModalShow(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <h4>Select Date Range</h4>
        {props.modalShow && (
          <>
            <div className="row mb-20">
              <div className="col-12 ">
                <DateRangePicker
                  className="d-flex flex-wrap"
                  showPreview={false}
                  inputRanges={[]}
                  onChange={handleChangeDateRange}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={1}
                  ranges={currentState}
                  direction="horizontal"
                  staticRanges={[
                    ...defaultStaticRanges,
                    {
                      label: "This Year",
                      range: () => ({
                        startDate: new Date(currentYear, 0, 1),
                        endDate: new Date(),
                      }),
                      isSelected(range) {
                        const definedRange = this.range();
                        return (
                          isSameDay(range.startDate, definedRange.startDate) &&
                          isSameDay(range.endDate, definedRange.endDate)
                        );
                      },
                    },
                    {
                      label: "All Time",
                      range: () => ({
                        startDate: new Date("2022-01-01"),
                        endDate: new Date(),
                      }),
                      isSelected(range) {
                        const definedRange = this.range();
                        return (
                          isSameDay(range.startDate, definedRange.startDate) &&
                          isSameDay(range.endDate, definedRange.endDate)
                        );
                      },
                    },
                  ]}
                />
              </div>
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          color="primary"
          className="text-white mb-10 mr-3"
          onClick={handleSumbitDateRange}
        >
          Save Range
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalComponent;
