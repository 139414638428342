import { LAYERS } from "./layers";
import { MAP,SUPER_CLUSTER } from "./mapbox";
import { TILELAYERS } from "./tileLayers";
export { MAP, LAYERS, TILELAYERS, SUPER_CLUSTER };

// Defines a set of constant paths for routing.
export const NAVIGATION_ROUTE = {
  main: "/",
  homepage: "/homepage",
};
/**
 * IMEI id's to filter markers against
 */
export const IMEIs = [
  "xHtssg6Ns6wP93MlOzWi",
  "u8bCTxPY2lu8Xud0Wj9b",
  "iRVjJw1ign9cz4ZlmUEY",
  "3HKboJqjMFD4qkUylfYA",
  "z6OeFJ0HTkOa1zdfr6lb",
  "ZkG150t2scLZs9JKK0Wv",
  "zziSvNb4ekXfX3ob5YE7",
  "ebGFn3C2cGhv8tnZNsWK",
  "0C7Fk3uNZAfz6m0ISm1h",
  "o7dO5vmO9iHO2tHdM1qY",
  "emqrgiwOBSHW4dF6c4el",
  "JXVcrQ4ZQWq1ZMonqi4K",
  "n0UCeYquuNaJFnxo0L9c",
  "fo0Ki7ZRpWzk3z5eMbP8",
];
/**
 * User roles for role based access
 */
export const ROLES = {
  ADMIN: "admin",
  USER: "user",
};
/**
 * Image modes for marker as stored in captured image details collection in firebase.
 */
export const IMG_MODES = {
  panorama: "panorama",
  snapshot: "snapshot",
  waypoint: "waypoint",
};
/**
 * Coordinates to filter for points in a certain region (El salvador in this case).
 */
export const COORDS_POINT = {
  minLat: 13.1485,
  minLong: 14.4511,
  maxLat: -87.6937,
  maxLong: -90.1344,
};
/**
 * Validation regex for login schema
 */
export const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
export const Toast = {
  login: {
    SUCCESS: "Login Successfully!",
    ERROR: "You have entered an invalid username or password.",
  },
  capturedImages:{
    SUCCESS: "Data fetched Successfully!",
    ERROR: "Something went Wrong!",
  },
  props:{
    position: "bottom-left",
    autoClose: 1000
  }
}

export const API_END_POINT = {
  login: '/login',
  capturedImages: {
    getAll: '/captured_images',

  },

};