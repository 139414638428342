import React from "react";
import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";

import { IMG_MODES } from "../../constants";
import TextComponent from "../textComponent";
import "./index.css";

/**
 * Component for image gallery carousel and circular text in overlay screen.
 * The circular text in overlay screen is being used currently.
 * @param {object} props
 * @param {object} props.clickedMarker Marker data object of selected object
 * @param {object} props.setImgGallery Setter for opening image gallery
 * @param {object} props.triggerOverlay Boolean valure for overlay view
 * @param {object} props.imgGallery Boolean valure for image gallery
 * @returns Gallery carousel in case of gallery view and renders circular text for image mode in case of overlay screen
 */
const CarouselComponent = (props) => {
  let arr = [];
  if (
    props.clickedMarker?.capturedImageMode === IMG_MODES.panorama &&
    props.clickedMarker?.panoramaImages?.length > 1
  ) {
    /**
     * Creating images array to be used as props for Carousel from react-gallery-carousel.
     * The following implementaion handles logic for displaying images of all markers of a supercluster 
     * when image mode of clicked marker is panorama.
     */
    if (props.overrideData.length >= 1) {
      props.overrideData?.forEach((element) => {
        if (
          element.capturedImageMode === IMG_MODES.panorama &&
          element.panoramaImages?.length >= 1
        ) {
          arr = [...arr, { src: element.imageRemoteUrl }];
          element.panoramaImages.forEach(
            (item) => (arr = [...arr, { src: item.imageRemoteUrl }])
          );
        } else {
          arr = [...arr, { src: element.imageRemoteUrl }];
        }
      });
    }
  } else {
    arr = [];
    if (props.overrideData.length >= 1) {
      props.overrideData?.forEach((element) => {
        /**
         * In case of panorama as image mode concatenate markers image url and its linked panoramaImages url, whereas 
         * creating images array for other image modes for both cases i.e; cluster (overrideData.length >= 1) and indivisual marker. 
         */
        if (
          element.capturedImageMode === IMG_MODES.panorama &&
          element.panoramaImages?.length >= 1
        ) {
          arr = [...arr, { src: element.imageRemoteUrl }];
          element.panoramaImages.forEach(
            (item) => (arr = [...arr, { src: item.imageRemoteUrl }])
          );
        } else {
          arr = [...arr, { src: element.imageRemoteUrl }];
        }
      });
    }
  }
  const closeGallery = () => {
    props.setImgGallery(false);
  };
  const error = console.error;
  console.error = (...args) => {
    if (/defaultProps/.test(args[0])) return;
    error(...args);
  };

  return (
    <>
    {/* Renders Rounded Text Comonent in Overlay view if image gallery view is false. */}
      {!props.imgGallery && props.triggerOverlay && (
        <TextComponent clickedMarker={props.clickedMarker} setImgGallery={props.setImgGallery}></TextComponent>
      )}
      {/* Renders Gallery Carousel for each marker if image gallery view is true. */}
      {props.imgGallery && (
        <div
          className="image-gallery-view"
          style={{
            width: "100%",
            height:"100%",
            position: "absolute",
            zIndex: 14,
            backgroundColor: "black",
            top:'0px'
          }}
        >
          <div
            style={{
              zIndex: 100001,
              top: "24px",
              position: "relative",
              textAlign: "end",
              paddingRight: "24px",
            }}
          >
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a>
              <img
                className="close-icon"
                src="/icons/ControlIcons/Property 1=Default.svg"
                alt="close-icon"
                onClick={closeGallery}
                style={{
                  pointerEvents: "all",
                  border: "none",
                  background: "transparent",
                  width: "30px",
                  height: "30px",
                }}
              ></img>
              <img
                className="close-icon"
                src="/icons/ControlIcons/Property 1=Hover.svg"
                alt="close-icon"
                onClick={closeGallery}
                style={{
                  pointerEvents: "all",
                  border: "none",
                  background: "transparent",
                  width: "30px",
                  height: "30px",
                }}
              ></img>
            </a>
          </div>
          <Carousel
            images={arr}
            canAutoPlay={false}
            hasSizeButton={false}
            objectFit="contain"
            objectFitAtMax="contain"
            hasLeftButton={false}
            hasRightButton={false}
            hasThumbnails={
              props.clickedMarker?.capturedImageMode === IMG_MODES.panorama ||
              props.overrideData.length > 1
            }
            hasIndexBoard={
              props.clickedMarker?.capturedImageMode === IMG_MODES.panorama ||
              props.overrideData.length > 1
                ? "topLeft"
                : false
            }
            style={{
              top: "-2rem",
              height: "100vh",
            }}
          />
        </div>
      )}
    </>
  );
};
export default CarouselComponent;


