import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authReducer from './slices/authSlice';
import capturedImagesReducer from './slices/capturedImagesSlice';
const persistConfig = {
  key: "root",
  storage, // Storage engine (e.g., localStorage)
  whitelist: ['auth']
};
/**
 * combine Reducers in case of more than one reducer as follows. Can also use configureStore.
 */
const reducers = combineReducers({
  auth: authReducer,
  capturedImages: capturedImagesReducer
});

export default persistReducer(persistConfig, reducers);