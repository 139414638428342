import * as Yup from 'yup';
// import { passwordRules } from '../utils';
/**
 * login schema to validate login form controls using Yup, a JavaScript object schema validator.
 */
export const LoginSchema = Yup.object().shape({
  email: Yup.string().required('Required'),
  password: Yup
  .string()
  .min(3,'Password must be greater than 2 characters')
  // .matches(passwordRules, { message: "Please create a stronger password" })
  .required("Required"),
});

