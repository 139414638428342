/**
   * This function handles count of markers associated within a cluster based on its id,
   * checking children and leaves of the cluster.
   * @param {String} clusterId
   * @returns return marker count for cluster
   */
export const getCount = (
    clusterId,
    supercluster,
    uniqueMarkerData,
    setuniqueMarkerData,
    map
  ) => {
    let markersCount = 0;
    let tempMarkerData = [];
    let result = [];
    let final = [];
    let allLeaves = [];
    let childs = supercluster?.getChildren(clusterId);
    let markerLeaves = [];
    let uniqueArr = uniqueMarkerData;
    let tempMarkerIndex;
    let leaves = supercluster?.getLeaves(clusterId);
    let first = false;
    let tempMarkerIndex1;
  
    for (let i = 0; i < childs.length; i++) {
      if (childs[i].id) {
        result.push(childs[i].id);
        if (!final.includes(childs[i].id)) final.push(childs[i].id);
        result.forEach((id) => {
          childs.push(...supercluster?.getChildren(id));
          result.pop(id);
        });
      } else {
        tempMarkerData.push(childs[i]);
      }
    }
  
    final.forEach((id, number) => {
      allLeaves.push(...supercluster?.getLeaves(id));
      markerLeaves = supercluster?.getLeaves(id);
      for (let j = 0; j < markerLeaves.length; j++) {
        if (uniqueArr.hasOwnProperty(markerLeaves[j].properties.marker_data.location)) {
          tempMarkerIndex = j;
          break;
        }
      }
      if (tempMarkerIndex === undefined) tempMarkerIndex = 0;
      markerLeaves.forEach((it, ind) => {
        if (
          ind !== tempMarkerIndex &&
          uniqueArr[markerLeaves[tempMarkerIndex]?.properties?.marker_data?.location] &&
          it?.properties?.marker_data?.location &&
          uniqueArr[it?.properties?.marker_data?.location] &&
          !uniqueArr[markerLeaves[tempMarkerIndex]?.properties?.marker_data?.location].includes(
            it?.properties?.marker_data
          )
        ) {
          uniqueArr[markerLeaves[tempMarkerIndex].properties.marker_data.location] = [
            ...uniqueArr[markerLeaves[tempMarkerIndex].properties.marker_data.location],
            ...uniqueArr[it.properties.marker_data.location],
          ];
          delete uniqueArr[it.properties.marker_data.location];
        }
      });
    });
  
    setuniqueMarkerData(uniqueArr);
    allLeaves = [...allLeaves, ...tempMarkerData];
    allLeaves.concat(leaves);
    allLeaves = [
      ...new Map(
        allLeaves.map((item) => [item.properties.marker_id, item])
      ).values(),
    ];
  
    allLeaves.forEach((leave) => {
      if (uniqueArr[leave?.properties?.marker_data?.location]) {
        if (!first) {
          tempMarkerIndex1 = leave?.properties?.marker_data?.location;
        }
        first = true;
        markersCount += uniqueMarkerData[leave?.properties?.marker_data?.location].length;
        if (
          map.current.getZoom() >= 16 &&
          uniqueArr[tempMarkerIndex1] &&
          tempMarkerIndex1 !== leave?.properties?.marker_data?.location
        ) {
          map.current.setMaxZoom(18);
          uniqueArr[tempMarkerIndex1] = [
            ...uniqueArr[tempMarkerIndex1],
            ...uniqueArr[leave?.properties?.marker_data?.location],
          ];
          delete uniqueArr[leave?.properties?.marker_data?.location];
        }
      } else {
        if (map.current.getZoom() >= 16) map.current.setMaxZoom(18);
      }
    });
    return markersCount;
  };