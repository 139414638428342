import React from "react";
import { Formik, Form } from "formik";
import { ToastContainer } from "react-toastify";
import { LoginSchema } from "../../validations/loginValidation";
import CustomInput from "../CustomInput";
import "./index.css";
/**
 * This component renders login form and implements user validation and signIn functionality .
 * @Component
 * @param {object} props 
 * @param {fuction} props.onSumbitHandler Submit user function that takes user data and fetches markers data 
 * 
 * @returns 
 */
function Loginform(props) {
  return (
    <div  >
      {/* Formik for getting form state and validating these states against a validation schema and for 
      handling form submission.*/}
      <ToastContainer />
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        // Formik has validationSchema prop for Yup object schemas, here our LoginSchema will automatically transform 
        // Yup's validation errors into an object whose keys match values and touched.
        validationSchema={LoginSchema}
        onSubmit={props.onSumbitHandler}
      >
        {({ errors }) => (
          <div>
            <Form className="form-container">
              <div className="form-group">
                <CustomInput name="email" label="pin" autoComplete="off" />
                <CustomInput type="password" name="password" label="password" autoComplete="off" />
                <button
                  className={`form-button ${
                    errors.password || errors.email ? "error" : ""
                  }`}
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
}

export default Loginform;
