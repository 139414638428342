/**
 * This function removes previously rendering layers before , checks 
 * if a layer already exist and remove it.
 */
export function removePrevLayer(map,id, outlineLayer, regionlayer, sourceId) {
    if (map?.getLayer(`${outlineLayer}-${id}`)) {
      map?.removeLayer(`${outlineLayer}-${id}`);
    }
    if (map?.getLayer(`${regionlayer}-${id}`)) {
      map?.removeLayer(`${regionlayer}-${id}`);
    }
    if (map?.getSource(`${sourceId}-${id}`)) {
      map?.removeSource(`${sourceId}-${id}`);
    }


    if (map?.getLayer(`${outlineLayer}-${id}-clone`)) {
      map?.removeLayer(`${outlineLayer}-${id}-clone`);
    }
    if (map?.getLayer(`${regionlayer}-${id}-clone`)) {
      map?.removeLayer(`${regionlayer}-${id}-clone`);
    }
    if (map?.getSource(`${sourceId}-${id}-clone`)) {
      map?.removeSource(`${sourceId}-${id}-clone`);
    }
  }