import React, { useState } from "react";
import "react-gallery-carousel/dist/index.css";
// import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import moment from "moment";

import { IMG_MODES } from "../../constants";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { db } from "../../configs/firebase";
import "./index.css";

/**
 * Rounded text component to be used in Overlay Screen, not in use Currently.
 * @param {object} props.clickedMarker Marker data object of selected object
 * @param {function} props.setImgGallery Setter for opening image gallery
 * 
 * @returns returns rendered Rounded Text for clicked Markers.
 */
const TextComponent = (props) => {
  const [localUrl, setLocalUrl] = useState();
  const storage = getStorage(db);
  /**
   * Get image url for markers that have their imageRemoteUrl key in marker object as null
   */
  const pathReference = ref(
    storage,
    process.env.REACT_APP_FIREBASE_IMAGE_BASEURL +
      `capturedImages/${props.clickedMarker.id}`
  ); // Creating path for clicked markers corresponding image in "capturedImages" bucket.
  getDownloadURL(pathReference)
    .then((url) => {
      setLocalUrl(url);
    })
    .catch(() => {
      setLocalUrl(undefined);
    });
  /**
   * Calculation for Rounded Image for "Transform" property
   */
  let totalCharsDate = moment(props.clickedMarker?.dateTime)
    .format("YYYY-MM-DD")
    .toUpperCase()?.length;
  let totalCharsImg = props.clickedMarker?.capturedImageMode.length;
  const angleIncrementDate = 50 / (totalCharsDate - 2);
  const angleIncrementImg = 60 / (totalCharsImg - 2);
  let currentAngle = 40;
  let currentAngle_ = -40;
  let thumbnailClassTopBar =
    props?.clickedMarker?.capturedImageMode === IMG_MODES.panorama
      ? "thumbnail-landscape"
      : props?.clickedMarker?.capturedImageMode === IMG_MODES.waypoint
      ? "thumbnail-waypoint"
      : "thumbnail-portrait";

  const handleGallery = () => {
    props.setImgGallery(true);
  };
  return (
    <div className="wrap">
      <div
        style={{
          position: "relative",
          width: "33vh",
          height: "33vh",
          borderRadius: "50%",
          backgroundImage:
            props.clickedMarker?.imageRemoteUrl != null
              ? `url(${props.clickedMarker?.imageRemoteUrl})`
              : `url(${localUrl})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          pointerEvents: "all",
          // paddingLeft: "3%",
        }}
        onClick={handleGallery}
      >
        <div className="toptext inversed">
          {props.clickedMarker?.capturedImageMode
            .split("")
            .reverse()
            .map((char, i) => {
              const style =
                props.clickedMarker?.capturedImageMode === IMG_MODES.panorama
                  ? {
                      // height: i >= 4  ? "40vh" : "33vh",
                      lineHeight: i >= 5 ? "34.3vh" : "33vh",
                      transform:
                        i === 0
                          ? `rotate(${currentAngle_ + 3}deg)`
                          : `rotate(${currentAngle_}deg)`,
                      transformOrigin: `0 ${34}vh`,
                    }
                  : {
                      // height: i >= 4  ? "40vh" : "33vh",
                      lineHeight: i >= 5 ? "34.3vh" : "33vh",
                      transform: `rotate(${currentAngle_}deg)`,
                      transformOrigin: `0 ${34}vh`,
                    };

              currentAngle_ -= angleIncrementImg;

              return (
                <span key={`heading-span-${i}`} style={style}>
                  {char}
                </span>
              );
            })}
          <div className="carousel-control-bar">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              <div className={thumbnailClassTopBar}></div>
            </div>
            {/* <FullscreenIcon className="carousel-icon"></FullscreenIcon> */}
            {props.clickedMarker?.panoramaImages?.length ? (
              <h6 className="carousal-bar-text">
                1/{1 + props.clickedMarker?.panoramaImages?.length}
              </h6>
            ) : (
              <h6 className="carousal-bar-text">1/1</h6>
            )}
            {/* <ArrowRightIcon className="carousel-icon"></ArrowRightIcon> */}
          </div>
        </div>
        <div className="toptext">
          {moment(props.clickedMarker?.dateTime)
            .format("YYYY-MM-DD")
            .toUpperCase()
            ?.split("")
            .map((char, i) => {
              const style = {
                // height: "35vh",
                transform: `rotate(${currentAngle}deg)`,
                paddingTop: `${totalCharsDate * 0.8}px`,
              };
              totalCharsDate--;

              currentAngle -= angleIncrementDate;

              return (
                <span key={`heading-span-${i}`} style={style}>
                  {char}
                </span>
              );
            })}
        </div>
      </div>
    </div>
  );
};
export default TextComponent;
