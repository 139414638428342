import { collection, getDocs, query, where } from "@firebase/firestore";
import { firestore } from "../configs/firebase";
import { ROLES } from "../constants";
/**
 * Function for getting coordinates data from Firebase
 */
export const fetchCoordData = async (role, clientVisibility = "") => {
  let admin_query;
  try {
    const document_ref = collection(firestore, "SAM");
    if (clientVisibility && clientVisibility.length > 0) {
      admin_query = query(document_ref, where("clientVisibility", "==", true));
    } else {
      admin_query = query(document_ref);
    }
    let querySnapshot;
    if (role === ROLES.ADMIN) {
      querySnapshot = await getDocs(admin_query);
    } else if (role === ROLES.USER) {
      // Query Snapshot for USER
      let user_query = query(
        document_ref,
        where("clientVisibility", "==", true)
      );
      querySnapshot = await getDocs(user_query);
    }

    let fetchedCoord = [];
    if (!querySnapshot.empty) {
      querySnapshot.forEach((doc) => {
        fetchedCoord.push({ id: doc.id, ...doc.data() });
      });
    } else {
      console.log("No data found in Firestore");
    }
    console.log(fetchedCoord)
    return fetchedCoord;
  } catch (err) {
    console.log("Error found while fetching data: ", err);
  }
};
