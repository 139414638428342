/**
 * 
 * This function removes all existing layers rendered on the map. The commented part is for the use case when all layers will be removed other than the selected AOI.
 */
export function removeAllOtherLayers(map, index, allLayers) {
    allLayers?.forEach((layer) => {
        if (
            (layer.id.startsWith("fused") ||
                layer.id.startsWith("overlay") || layer.id.startsWith("route")
            )
            // && (!layer.id.includes(`${index}`))
        ) {
            map?.removeLayer(layer.id);
        }
    });
    const sourcesToRemove = map?.getStyle().sources || {};
    Object.keys(sourcesToRemove).forEach((sourceId) => {
        if ((sourceId.startsWith("fused") || sourceId.startsWith("overlay") || sourceId.startsWith("overlay"))
            // && (!sourceId.includes(`${index}`))
        ) {
            map?.removeSource(sourceId);
        }
    });
}
export function removePopups(map){
    const popupsToRemove = [...map?._popups];
    popupsToRemove?.forEach(popup => popup.remove());
}
export function removeMarkers(map){
    map?._markers.forEach((marker) => {
        marker?.remove();
      });
}

