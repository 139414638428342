import { GEOJSON_ENCODED } from "./geojsondump";
/**
 * udf's tile layers to be added as sources with source names explaining what the tile would render.
 */
export const TILELAYERS = [
  {
    // This tile renders a RGB image from the Planetary Computer Sentinel-2 dataset.
    sourceLayer: [
      "https://www.fused.io/server/v1/realtime-shared/9cc509efde156b2fa174d2f9d7a9db71555a759a8d9f5e49fcb0b967e0466b7e/run/tiles/{z}/{x}/{y}?dtype_out_raster=png",
    ],
    sourceName: "PC_Sentinel2",
  },
  {
    // This tile will render AOI based on its geojson query param.
    sourceLayer: [
      `https://www.fused.io/server/v1/realtime-shared/fsh_1pXLH2vuvCqg6IzUNMqxoN/run/tiles/{z}/{x}/{y}?dtype_out_vector=mvt&target_gdf=${encodeURIComponent(
        JSON.stringify(GEOJSON_ENCODED)
      )}`,
    ],
    sourceName: "PC_PolygonLayer",
  },
  {
    /**
     * This tile retrieves satellite imagery data from Planetary Computer based on specified
     * parameters (collection, bands, time range, and query) and the satellite data source (Sentinel-2)
     */
    sourceLayer: [
      "https://www.fused.io/server/v1/realtime-shared/862509d4b923eaaad242eff7a3a069932ec01ede63e8978430089be07705c8bf/run/tiles/{z}/{x}/{y}?dtype_out_vector=mvt",
    ],
    sourceName: "GreenestPixel_Sentinel",
  },
  {
    /**
     * This tile retrieves satellite imagery data from Planetary Computer based on specified
     * parameters (collection, bands, time range, and query) and the satellite data source (Landsat)
     */
    sourceLayer: [
      "https://www.fused.io/server/v1/realtime-shared/1b080173ef11eb99b2bde259201130a0838c415083c4b7a51c800ea01a22f7d0/run/tiles/{z}/{x}/{y}?dtype_out_vector=mvt",
    ],
    sourceName: "GreenestPixel_Landsat",
  },
  {
    /**
     * This tile retrieves satellite imagery data from Planetary Computer based on specified
     * parameters (collection, bands, time range, and query) and the satellite data source (MODIS)
     */
    sourceLayer: [
      "https://www.fused.io/server/v1/realtime-shared/816e3e3ee8298a2e1a454edb222a0ed1d31ce9569637b81bfb151837f9dbc393/run/tiles/{z}/{x}/{y}?dtype_out_vector=mvt",
    ],
    sourceName: "GreenestPixel_Modis",
  },
];
