
/**
* Mapbox layers based on street and satellite view with carying themes.
*/


export const LAYERS={
    STREETV11: "mapbox://styles/mapbox/streets-v11", // for showing street view as gmaps
    SETALLITEV9: "mapbox://styles/mapbox/satellite-v9", //setallite-v9 //it shows in mercerator view
    SETALLITEV12: "mapbox://styles/mapbox/satellite-streets-v12", //statellite view along with streets labelling
    DARK1: "mapbox://styles/saadasad/cll3uggz800gc01p8eezpb4a2", // It shows in login form
    ILLUMINATED1: "mapbox://styles/saadasad/cll3ugjb200gr01pmawnlfp3p",
    ILLUMINATED2: "mapbox://styles/saadasad/cll3uglxx00h101qp5ipleo0p",
    ILLUMINATED4: "mapbox://styles/mapbox/satellite-streets-v11",
    ISLANDVIEW: "mapbox://styles/saadasad/cm1oxdnej00ka01qrcl1d55cp", // It shows white map such that rendered udf's AOI is highlighted.
    // SETALLITEV9: "mapbox://styles/saadasad/clkccs4od000x01pobsfwarpg", //custom
    
}
