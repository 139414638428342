// authSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { postData } from '../../api/apiClient';
import { API_END_POINT } from '../../constants';
import { persistedStore } from '..';
/**
 * Initial state object
 */
const initialState = {
    isAuthenticated: false,
    user: null,
    isLoading: false,
    isSuccess: false,
    error: null
};
/**
 * This slice has asynchronous functions defined for login and logout. These will include any fetch requests and will handle sending that 
 * information to the frontend UI component.
 */
export const login = createAsyncThunk(
    'login',
    async (params, thunkApi) => {

        try {
            persistedStore.persist();
            const response = await postData(`${API_END_POINT.login}`, { userName: params.email, password: params.password })
            if (response.status === 200) {
                const resData = response.data.token;
                return resData
            } else {
                throw new Error(response.error);
            }
        } catch (err) {
            const defaultErrResponse = {
                errCode: err.response?.status,
                errMsg: 'Something went wrong.'
            }
            const errResponse = err?.response?.data && (err?.response?.data.errMsg) ? err?.response?.data : defaultErrResponse
            return thunkApi.rejectWithValue(errResponse);
        }
    }
);

export const logout = createAsyncThunk(
    'logout',
    async (_, thunkApi) => {
        try {
            persistedStore.pause();
            persistedStore.flush().then(() => {
                return persistedStore.purge();
            });
            return {};
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }
    });

export const signup = createAsyncThunk(
    'signup',
    async (_, thunkApi) => {
        try {

        } catch (error) {
            return thunkApi.rejectWithValue(error)

        }
    })


const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    /**
     * ExtraReducers take the information from our asynchronous fetches and 
     * then use that data to manipulate global state
     */
    extraReducers: (builder) => {
        // Handle login cases
        builder
            .addCase(login.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(login.fulfilled, (state, action) => {
                state.isLoading = false;
                state.user = action.payload;
                state.isSuccess = true;
                state.isAuthenticated = true;
                state.error = null;
            })
            .addCase(login.rejected, (state, action) => {
                state.isLoading = false;
                state.isAuthenticated = false;
                state.error = action.payload;


            });

        // Handle logout cases
        builder
            .addCase(logout.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(logout.fulfilled, (state) => {
                state.isLoading = false;
                state.isAuthenticated = false;
                state.isSuccess = false;
                state.user = {};
            })
            .addCase(logout.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload.message;
            });

        //Handle SignUp Cases
        builder
            .addCase(signup.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(signup.fulfilled, (state) => {
                state.isLoading = false;
                state.isSuccess = false;
                state.user = {};
            })
            .addCase(signup.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload.message;
            });
    },
});

// export const { login, logout } = authSlice.actions;
export const selectAuth = (state) => state.auth;
export default authSlice.reducer;





