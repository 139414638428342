export const MAP = {
  /**
   * Following is mapbox's initial configuration listed for zoom values, center coordinates, cluster properties
   * and projection options.
   */
  initialZoom: 10,
  finalZoom: 18,
  centerLng: 28.219039,
  centerLat: -26.166746,
  projection: { mercator: "mercator", globe: "globe" },
};

/**
 * Configuration object for clustering behavior of SuperCluster Library.
 * 
 * Additional Available Options:
 * - `minZoom` (number): Minimum zoom level at which clusters are generated. Default: 0
 * - `radius` (number):	Cluster radius, in pixels. Default: 40
 * - `maxZoom` (number): Maximum zoom level at which clusters are generated. Default: 16
 * - `minPoints` (number): Minimum number of points to form a cluster. Default: 2
 * - `extent` (number): Tile extent. Radius is calculated relative to this value. Default: 512
 * - `nodeSize` (number): Size of the KD-tree leaf node. Affects performance. Default: 64
 */
export const SUPER_CLUSTER = {
  radius: 2, 
  minZoom: 2, 
  maxZoom: 17, 
  minPoints: 2,
  extent: 512,
  nodeSize: 64
};