import React, { useState, useContext, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { mapboxgl } from "../configs/mapbox";
import moment from "moment";
import { store } from "../store";
import { updateCapturedImages } from "../store/slices/capturedImagesSlice";
import {
  renderTiles,
  uploadAndCaptureImages,
  renderMarkers,
  renderPolygons,
  updateClusters,
  fetchCoordData,
  removePolygons,
} from "../utils";
import {
  removeAllOtherLayers,
  removeMarkers,
  removePopups,
} from "../utils/removeSources";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { login, logout } from "../store/slices/authSlice";
import HeaderComponent from "../components/headerComponent";
import CarouselComponent from "../components/CarouselComponent";
import Loginform from "../components/LoginForm";
import MapContext from "../contexts/mapContext";
import Icon from "../controllers/IconControllers/Icon";
import InlineSVG from "../controllers/IconControllers/inlineSvg";
import { ROLES, IMEIs, Toast } from "../constants";
import { LAYERS, TILELAYERS } from "../constants";
import "../components/MapProvider/index.css";
import "./index.css";
import { capturedImages } from "../store/slices/capturedImagesSlice";
/**
 * Main Component for map rendering and the implementation of rendering markers, clusters and polygon segments implementation.
 * @Component
 * @returns Returns main component which renders is home page
 */

function MainPage() {
  const [selectedImageMode, setSelectedImageMode] = useState("");
  const [currentMarkers, setCurrentMarkers] = useState("");
  const [currentSegments, setCurrentSegments] = useState("");
  const [markerDataLoading, setMarkerDataLoading] = useState(false);
  const [isRenderPolygon, setIsRenderPolygon] = useState(false);
  const [polygonDataLoading, setPolygonDataLoading] = useState(false);
  const [imgGallery, setImgGallery] = useState(false);
  const [layerIndex, setLayerIndex] = useState(0);
  const [fetchedData, setFetchedData] = useState(null);
  const [uniqueMarkerData, setUniqueMarkerData] = useState({});
  const [clickedMarker, setClickedMarker] = useState({});
  const markersRef = useRef([]);
  const copiedMarkerRef = useRef([]);
  const focusedRef = useRef(false);
  const [currentDate] = useState(new Date());
  // Date Range defaults to October 7th - 10th ,2024
  const [startDate, setStartDate] = useState(moment(new Date(2024, 9, 7)).format("YYYY-MM-DDTHH:mm:ss.SSSS[Z]")); // Change this with your desired initial start date
  const [endDate, setEndDate] =     useState(moment(new Date(2025, 3, 30)).format("YYYY-MM-DDTHH:mm:ss.SSSS[Z]")); // Change this with your desired initial end date
  const { mapRef, mapContainerRef, superCluster, isLoading, zoom } =
    useContext(MapContext);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const dispatch = useDispatch();

  /**
   * Segments Function
   */
  const getCoordinatesAndRenderPolygons = async () => {
    let coord = await fetchCoordData(ROLES.ADMIN, currentSegments);
    if (coord) {
      const sourceData = coord.map((obj) => {
        return {
          id: obj.id,
          clientVisibility: obj.clientVisibility || false,
          coordinates: obj.coordinates.map((data) => [data._long, data._lat]),
        };
      });
      renderPolygons(mapRef.current, sourceData);
      // renderLinks(mapRef.current)
    }
  };
  /**
   * This function calls update cluster when user interacted
   */
  function handleMoveEnd() {
    if (isAuthenticated !== false && mapRef.current?._markers.length !== 0) {
      updateClusters(
        superCluster?.current,
        mapRef,
        markersRef,
        uniqueMarkerData,
        fetchedData,
        setUniqueMarkerData
      );
    }
  }
  /**
   * This function toggles between several tile layers( udf's) rendered in top of mapbox's style layer.
   */
  function toggleMapLayer() {
    setLayerIndex((layerIndex + 1) % TILELAYERS.length);
    const activeLayer = TILELAYERS[(layerIndex + 1) % TILELAYERS.length];
    mapRef.current.getStyle().name !== "Untitled" &&
      mapRef.current?.setStyle(LAYERS?.ISLANDVIEW);
    if (mapRef.current.getStyle().name !== "Untitled") {
      const onStyleLoad = () => {
        renderTiles(mapRef.current, activeLayer.sourceLayer);
        mapRef.current.off("style.load", onStyleLoad);
      };
      mapRef.current.on("style.load", onStyleLoad);
    } else {
      renderTiles(mapRef.current, activeLayer.sourceLayer);
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        setMarkerDataLoading(true); // sets marker loading true , used for displaying loader on top of map
        //  fetch markers data based on applied filters
        let data = [];
        const body = {
          capturedImageMode: selectedImageMode,
          // clientVisibility: false,
          startDateTime: startDate,
          endDateTime: endDate,
          // pageNumber: 1
        };
        const fetchedResult = await dispatch(capturedImages(body));
        if(fetchedResult?.payload?.errCode==401){
          // dispatch(logout )
          handleLogout()
        }
        if (capturedImages.fulfilled.match(fetchedResult)) {
          data = fetchedResult.payload;
        } else if (capturedImages.rejected.match(fetchedResult)) {
          setMarkerDataLoading(false);
          toast?.error(Toast.capturedImages.ERROR, {
            position: Toast.props.position,
            autoClose: Toast.props.autoClose,
          });
        }
        let uniqueItems = {};
        data?.forEach((item) => {
          if (!uniqueItems[item.location]) uniqueItems[item.location] = [item];
          else
            uniqueItems[item.location] = [...uniqueItems[item.location], item];
        });
        let arrayUniqueByKey = [
          ...new Map(data?.map((item) => [item["location"], item])).values(),
        ];
        arrayUniqueByKey = arrayUniqueByKey?.filter(
          (markerData) => !IMEIs.includes(markerData.mobileImeiReference)
        );
        setUniqueMarkerData(uniqueItems);
        dispatch(updateCapturedImages(arrayUniqueByKey));
        setFetchedData(arrayUniqueByKey);
        setMarkerDataLoading(false); // sets marker loading false , used for displaying loader on top of map
      } catch (err) {
        console.log(err);
      }
    };
    if (isAuthenticated === true && !isLoading) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isAuthenticated,
    selectedImageMode,
    startDate,
    endDate,
    currentMarkers,
    isLoading,
  ]);
  useEffect(() => {
    let markersData = [];
    markersData.length = 0;
    markersRef.current = markersData;
    setPolygonDataLoading(true);
    isRenderPolygon
      ? getCoordinatesAndRenderPolygons(mapRef.current)
      : removePolygons(mapRef.current, mapRef.current?.getStyle()?.layers); //handles polygon icon toggle to show and remove segments/polygons.
    setPolygonDataLoading(false);
    mapRef.current &&
      renderTiles(mapRef.current, TILELAYERS[1].sourceLayer, focusedRef);
    if (fetchedData?.length > 0 && mapRef.current) {
      // calls renderMarkers function for custom markers creation and rendering using fetchedData.
      if (superCluster) {
        renderMarkers(
          markersData,
          fetchedData,
          uniqueMarkerData,
          markersRef,
          copiedMarkerRef,
          superCluster,
          mapRef,
          setUniqueMarkerData,
          setClickedMarker,
          setImgGallery
        );
        mapRef?.current.on("moveend", handleMoveEnd);
        mapRef?.current.on("rotate", function () {
          var rotation = mapRef.current?.getBearing();
          if (copiedMarkerRef.current.length > 0) {
            for (let i = 0; i < copiedMarkerRef.current.length; i++) {
              const previousRotation =
                copiedMarkerRef.current[i]?.getRotation();
              markersRef.current[i].setRotation(
                (Math.abs(rotation) + previousRotation) % 360
              );
            }
          }
        });
      }
    }

    return () => {
      if (mapRef?.current) {
        mapRef?.current?.off("moveend", handleMoveEnd);
      }
      if (markersData.length > 0) {
        markersData.forEach((marker) => {
          marker.remove();
        });
        markersData.length = 0;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedData, isRenderPolygon]);

  /**
   * This Function submits user credentials and calls dispatch function from redux store to login user and adds navigation controls on bottom-right of the map.
   * @param {object} user User object for either admin or user role based on login credentials
   */

  const onSumbitHandler = async (data) => {
    try {
      const loginResult = await dispatch(login(data));
      if (login.rejected.match(loginResult)) {
        toast?.error(loginResult.payload.errMsg, {
          position: Toast.props.position,
          autoClose: Toast.props.autoClose,
        });
      } else if (login.fulfilled.match(loginResult)) {
        mapRef?.current.addControl(
          new mapboxgl.NavigationControl(),
          "bottom-right"
        );
        toast?.success(Toast.login.SUCCESS, {
          position: Toast.props.position,
          autoClose: Toast.props.autoClose,
        });
        mapRef.current?.setStyle(LAYERS.SETALLITEV9);

      }
    } catch (err) {
      console.error("An unexpected error occurred:", err);
    }
  };
  /**
   * This function handles toggle functionality for rendering and removig polygons from map screen.
   */
  const togglePolygonStyle = () => {
    setIsRenderPolygon((isRenderPolygon) => !isRenderPolygon);
    const polygonIcon = document.getElementById("PolygonIcon");
    polygonIcon.classList.toggle("polygonIcon-selected");
  };

  /**
   * This function handles logout functionality , removes rendered markers, popup if it is still open, closes overlay screen,
   * clears user data from redux store and removes the navigation controls from map screen.
   */
  function handleLogout() {
    setFetchedData([]);
    removeAllOtherLayers(
      mapRef?.current,
      "",
      mapRef.current?.getStyle()?.layers
    );
    setIsRenderPolygon(false);
    mapRef.current?.setStyle(LAYERS?.DARK1);
    removePolygons(mapRef?.current, mapRef.current?.getStyle()?.layers);
    removePopups(mapRef?.current);
    removeMarkers(mapRef?.current);
    // Removing mapbox navigations controls
    document
      .querySelector(" .mapboxgl-ctrl-group .mapboxgl-ctrl-zoom-in")
      ?.remove();
    document
      .querySelector(" .mapboxgl-ctrl-group .mapboxgl-ctrl-zoom-out")
      ?.remove();
    document
      .querySelector(" .mapboxgl-ctrl-group .mapboxgl-ctrl-compass")
      ?.remove();
    dispatch(logout());
  }

  return (
    <div className="map-wrapper">
      {/* Initialized map container in which map loaded, manipulating map via ref */}
      <div className="map-container" ref={mapContainerRef} id="map"></div>
      <>
        {isAuthenticated === true ? (
          <>
            {/* Loading indicator while  data fetching api call ... */}
            {markerDataLoading && (
              <div className="progress-bar-custom">
                <div className="progress-bar-value"></div>
              </div>
            )}
            {/* Header to show filters which can be applied */}
            <div className="header-container">
              <HeaderComponent
                selectedImageMode={selectedImageMode}
                setSelectedImageMode={setSelectedImageMode}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                currentMarkers={currentMarkers}
                setCurrentMarkers={setCurrentMarkers}
                role={ROLES.ADMIN}
                currentSegments={currentSegments}
                setCurrentSegments={setCurrentSegments}
              />
            </div>
            <div
              onClick={(e) => {
                e.stopPropagation();
                handleLogout();
              }}
              data-html2canvas-ignore="true"
            >
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a>
                <Icon
                  name="Property 1=Default-Log-out"
                  id={
                    isAuthenticated === true ? "LogoutIcon" : "LogoutIcon-user"
                  }
                  alt="logoutToggler"
                />
                <Icon
                  name="Property 1=Log-out-hover"
                  id={
                    isAuthenticated === true ? "LogoutIcon" : "LogoutIcon-user"
                  }
                  alt="logoutToggler"
                />
              </a>
            </div>
            <div
              onClick={toggleMapLayer}
              data-html2canvas-ignore="true"
              disabled={true}
              role="button"
              aria-disabled={true}
              tabIndex={-1}
            >
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a>
                <Icon
                  name="Property 1=Default-Map-view"
                  id={
                    isAuthenticated === true
                      ? "toggleButton"
                      : "toggleButton-user"
                  }
                  alt="mapToggler"
                />
                <Icon
                  name="Property 1=Map-view-Hover"
                  id={
                    isAuthenticated === true
                      ? "toggleButton"
                      : "toggleButton-user"
                  }
                  alt="mapToggler"
                />
              </a>
            </div>
            {/* Open gallery component when user clicks on marker */}
            {imgGallery && (
              <CarouselComponent
                clickedMarker={clickedMarker}
                setImgGallery={setImgGallery}
                imgGallery={imgGallery}
                overrideData={uniqueMarkerData[clickedMarker.location]}
              ></CarouselComponent>
            )}
            <ToastContainer />
          </>
        ) : (
          <Loginform onSumbitHandler={onSumbitHandler}></Loginform>
        )}
      </>
    </div>
  );
}

export default MainPage;
