import axios from 'axios';
import { store } from '../store';
const API_BASE = process.env.REACT_APP_BASE_URL;
// Request
axios.interceptors.request.use(
	function (config) {
		/** Request interceptors checks for authorization token and sends it along Auhorization header */
		const persistStore = store.getState()
		const isAuthenticated = persistStore?.auth?.isAuthenticated
		if (isAuthenticated) {
			const token = persistStore?.auth?.user
			config.headers['Content-Type'] = 'application/json';
			config.headers['token'] = `${token}`;
		}
		return config;
	},
	function (err) {
		console.log(err)
		return Promise.reject(err);
	}
);

export const responseHandler = (response) => {
	return { data: response.data, message: response.data.message, status: response.status };
}

// -------------------------------------------------------------------------//
// GET REQUEST
export const getData = async (END_POINT, id = null) => {
	let response;

	if (id) {
		response = await axios.get(API_BASE + END_POINT, {
			params: { id }
		});
	} else {
		response = await axios.get(API_BASE + END_POINT);
	}
	return responseHandler(response);
};


// -------------------------------------------------------------------------//
// get request with support of query
export const getDataWithQuery = async (END_POINT, queries = {}) => {
	let response;
	if (queries) {
		response = await axios.get(API_BASE + END_POINT, {
			params: queries
		});
	} else {
		response = await axios.get(API_BASE + END_POINT);
	}
	return responseHandler(response);
};


// -------------------------------------------------------------------------//
// POST REQUEST
export const postData = async (END_POINT, body, config = {}) => {
	const response = await axios.post(API_BASE + END_POINT, body, config)
	return responseHandler(response);
};


// -------------------------------------------------------------------------//
// PUT REQUEST
export const updateData = async (END_POINT, body, config = {}) => {
	const response = await axios.put(API_BASE + END_POINT, body, config);
	return responseHandler(response);
};


// -------------------------------------------------------------------------//
// DELETE REQUEST
export const deleteData = async (END_POINT, id) => {
	const response = await axios.delete(`${API_BASE}${END_POINT}`, {
		params: { id }
	});
	return responseHandler(response);
};

// -------------------------------------------------------------------------//
export const deleteDataWithBody = async (END_POINT, body) => {
	console.log('body', body);

	const response = await axios.delete(`${API_BASE}${END_POINT}`, {
		data: body
	});
	return responseHandler(response);
};









