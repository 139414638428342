import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from 'react-redux';
import { PersistGate } from "redux-persist/integration/react";
import { store, persistedStore } from "./store";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    {/* PersistGate is used to delay the rendering of the app until the persisted state has been retrieved 
      from storage.Here, 'loading={null}' means no loading indicator will be shown. */}
    <PersistGate loading={null} persistor={persistedStore}>
      {/* The main application component where the app's UI and logic starts. App component has access 
    to the Redux store and persisted state. */}
      <App />
    </PersistGate>
  </Provider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
