import { removePrevLayer } from "./removePrevLayer";
import { renderLinks } from "./renderLinks";
import { RASTER_GEOJSON, WORLD_BOUNDING_BOX } from "../constants/geojsondump";
import {
  addLayersFocusedView,
  removeLayersUnfocusedView,
} from "./handleIslandView";
import {
  addGeojsonSource,
  addRasterSource,
  addFillLayer,
  addLineLayer,
  removeLayerIfExists,
  removeSourceIfExists,
} from "./layerManager";

let toggleFocusViewHandler = null;

/**
 * This function takes coordinates of AOI's and returns the bounds so that no requests out of these bounds are sent for tiles.
 * @param {object} coords - Coordinates of the Area of Interest.
 * @returns {Array|null} - Bounding box for the Area of Interest or null if invalid input.
 */
function getBounds(coords) {
  if (!coords || !Array.isArray(coords) || coords.length === 0) {
    console.warn("Invalid coordinates provided to getBounds.");
    return null;
  }
  const longitudes = coords.map(([lng]) => lng);
  const latitudes = coords.map(([, lat]) => lat);
  const maxLng = Math.max(...longitudes);
  const minLng = Math.min(...longitudes);
  const maxLat = Math.max(...latitudes);
  const minLat = Math.min(...latitudes);
  return [minLng, minLat, maxLng, maxLat];
}

/**
 * Adds tile sources and layers for inverse polygons to the map.
 * @param {object} map - The map object to add sources and layers to.
 * @param {object} inversePolygons - GeoJSON data for inverse polygons.
 * @param {string} outlineClr - Color for the outline.
 */
export function addTileSources(map, inversePolygons, outlineClr) {
  if (!map || !inversePolygons) {
    console.warn("Invalid parameters provided to addTileSources.");
    return;
  }
  
  addGeojsonSource(map, "inverse-polygons", inversePolygons);
  addFillLayer(map, "inverse-fill", "inverse-polygons", "#000000", 0.70);
}

/**
 * Adds tile sources and fused raster layers to the map.
 * @param {object} map - The map object to add sources and layers to.
 * @param {number} index - Index to uniquely identify layers and sources.
 * @param {object} data - GeoJSON data for the layers.
 * @param {string} outlineClr - Color for the outline.
 */
export function addTileSourcesWithFused(map, index, data, outlineClr) {
  if (!map || !data || typeof index !== 'number') {
    console.warn("Invalid parameters provided to addTileSourcesWithFused.");
    return;
  }

  const bounds = getBounds(data.features[0]?.geometry.coordinates[0]);
  if (!bounds) {
    console.warn("Invalid bounds for fused tile source.");
    return;
  }

  addRasterSource(map, `fused-raster-source-${index}`, data, bounds);
  addLineLayer(map, `fused-raster-layer-${index}`, `fused-raster-source-${index}`, outlineClr);

  addGeojsonSource(map, `overlay-source-${index}`, data);
  addFillLayer(map, `overlay-layer-${index}`, `overlay-source-${index}`, null, 0);
  addLineLayer(map, `overlay-outline-${index}`, `overlay-source-${index}`, outlineClr, 5);
}

/**
 * Toggles between focused and unfocused state for the island view.
 * @param {object} map - The map object.
 * @param {number} index - Index to uniquely identify layers and sources.
 * @param {object} focusedState - State object indicating whether the view is focused.
 */
const toggleFocusView = (map, index, focusedState) => {
  if (!map || !focusedState || typeof index !== 'number') {
    console.warn("Invalid parameters provided to toggleFocusView.");
    return;
  }

  const isFocused = focusedState.current;
  
  if (!isFocused) {
    addTileSources(map, inversePolygons(), "#FFFFFF");
    addLayersFocusedView(map, map.getStyle().layers);
    renderLinks(map);
  } else {
    removeLayerIfExists(map, "inverse-fill");
    removeSourceIfExists(map, "inverse-polygons");
    removeLayersUnfocusedView(map, map.getStyle().layers);
  }
  focusedState.current = !isFocused;
};

/**
 * Creates inverse polygons from the bounding box and raster GeoJSON.
 * @returns {object|null} - GeoJSON for inverse polygons or null if data is missing.
 */
const inversePolygons = () => {
  if (!WORLD_BOUNDING_BOX || !RASTER_GEOJSON) {
    console.warn("Missing WORLD_BOUNDING_BOX or RASTER_GEOJSON for inversePolygons.");
    return null;
  }

  return {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            WORLD_BOUNDING_BOX.geometry.coordinates[0],
            ...RASTER_GEOJSON.map((obj) => obj.features[0]?.geometry.coordinates[0] || []),
          ],
        },
      },
    ],
  };
};

/**
 * Renders tiles on the map by adding necessary sources and layers.
 * @param {object} map - The map object.
 * @param {string} tile - Tile identifier.
 * @param {object} focusedState - State object indicating whether the view is focused.
 */
export function renderTiles(map, tile, focusedState) {
  if (!map || !tile) {
    console.warn("Invalid parameters provided to renderTiles.");
    return;
  }

  RASTER_GEOJSON.forEach((data, index) => {
    if (!data) {
      console.warn(`Skipping invalid data at index ${index}.`);
      return;
    }

    if (!toggleFocusViewHandler) {
      toggleFocusViewHandler = () => toggleFocusView(map, index, focusedState);
    }

    const polygonId = `excluded-polygon-${index}`;
    addGeojsonSource(map, polygonId, data);
    addFillLayer(map, polygonId, polygonId, null, 0);
    addLineLayer(map, `line-${polygonId}`, polygonId, "#FFFFFF", 4);

    map.off("click", polygonId, toggleFocusViewHandler);
    map.on("click", polygonId, toggleFocusViewHandler);
  });
}

/**
 * Renders tiles with fused data on the map.
 * @param {object} map - The map object.
 * @param {string} tile - Tile identifier.
 * @param {object} focusedState - State object indicating whether the view is focused.
 */
export function renderTilesWithFused(map, tile, focusedState) {
  if (!map || !tile || !focusedState) {
    console.warn("Invalid parameters provided to renderTilesWithFused.");
    return;
  }

  RASTER_GEOJSON.forEach((data, index) => {
    if (!data) {
      console.warn(`Skipping invalid data at index ${index}.`);
      return;
    }

    removePrevLayer(map, index, "", "fused-raster-layer", "fused-raster-source");
    removePrevLayer(map, index, "overlay-outline", "overlay-layer", "overlay-source");
    addTileSourcesWithFused(map, index, data, "#FFFFFF");
    removeLayersUnfocusedView(map, map?.getStyle()?.layers);

    if (!toggleFocusViewHandler) {
      toggleFocusViewHandler = () => toggleFocusView(map, index, focusedState);
    }

    map.off("click", `overlay-layer-${index}`, toggleFocusViewHandler);
    map.on("click", `overlay-layer-${index}`, toggleFocusViewHandler);
  });
}
