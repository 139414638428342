import React from "react";
import { useField } from "formik";
import { FormGroup, FormControl, FormLabel } from "react-bootstrap";
/**
 * This component handles reusable custom inputs and button for react forms.
 * @param {object} props This custom input component accepts several props including 
 * the input type, label text, value.
 * @param {string} props.name name of input control
 * @param {string} props.type  type of input control
 * @param {string} props.label label for the input control
 * @returns renders an input control form compoenent.
 */
function CustomInput({ label, ...props }) {
  const [field, meta] = useField(props);
  return (
    <>
      <FormGroup className="form-group">
        <FormLabel
          className={
            meta.error !== "" || meta.error ? "form-label" : "form-label-error"
          }
        >
          {label}
        </FormLabel>
        <div className="input-container">
          <FormControl
            className={`input-control ${meta.error ? "error" : ""}`}
            {...field} {...props} 
          />
           {meta.error && meta.touched ? <div className="text-danger">{meta.error}</div> : null}
        </div>

      </FormGroup>
    </>
  );
}

export default CustomInput;
