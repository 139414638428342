import { LINK_GEOJSON } from "../constants/geojsondump";
import { removePrevLayer } from "./removePrevLayer";
// import { generateIcon } from "./generateIcon";
import { moveLayers } from "./moveLayers";
import { mapboxgl } from "../configs/mapbox";
import {
  bezierSpline,
  nearestPointOnLine,
  point,
  lineString,
  along,
  midpoint,
} from "@turf/turf";
import ReactDOM from "react-dom/client";
import LineStringPopup from "../components/LineStringPopup";
import "../index.css";

/**
 * Renders a popup at the specified coordinate on the map.
 * @param {object} map - The map instance.
 * @param {Array} coordinate - The [longitude, latitude] coordinate where the popup should be placed.
 * @param {number} index - The index used to identify the popup.
 * @returns {object} The rendered popup.
 */
const renderPopup = (map, coordinate, index) => {
  const tempDOMElement = document.createElement("div");
  const popupContent = (
    <LineStringPopup map={map} coordinate={coordinate} index={index} />
  );
  ReactDOM.createRoot(tempDOMElement).render(popupContent);
  tempDOMElement.classList.add("toggle-popup");

  const popup = new mapboxgl.Popup({ closeOnClick: false, closeButton: false })
    .setLngLat(coordinate)
    .setDOMContent(tempDOMElement)
    .addTo(map);
  popup.id = index;

  return popup;
};

/**
 * Updates the size of the popup element based on the current zoom level.
 * @param {HTMLElement} element - The popup DOM element.
 * @param {number} zoom - The current zoom level of the map.
 */
const updatePopupSize = (element, zoom) => {
  if (!element || typeof zoom !== "number") return;

  const sizeFactor = Math.min(Math.max(zoom / 10, 1), 2);
  if (element.style) {
    element.style.width = `${100 * sizeFactor}px`;
    element.style.height = `${50 * sizeFactor}px`;
  }

  const baseFontSize = 8;
  element.childNodes.forEach((node) => {
    if (node instanceof HTMLElement) {
      node.style.fontSize = `${baseFontSize * sizeFactor}px`;
    }
  });
};

/**
 * Renders a route layer on the map.
 * @param {object} map - The map instance.
 * @param {object} lineData - The GeoJSON data for the line.
 * @param {number} index - The index used to identify the layer.
 */
const renderRouteLayer = (map, lineData, index) => {
  map?.addSource(`route-${index}`, {
    type: "geojson",
    data: lineData,
  });
  map?.addLayer({
    id: `route-${index}`,
    type: "line",
    source: `route-${index}`,
    layout: {
      "line-join": "round",
      "line-cap": "round",
    },
    paint: {
      "line-color": "#fce89d",
      "line-width": 1.5,
    },
  });
};

/**
 * Updates the position of the popup based on the nearest point on the line to the center of the map or the midpoint between AoIs.
 * @param {object} map - The map instance.
 * @param {object} popup - The popup instance to update.
 * @param {object} line - The GeoJSON line feature.
 */
const updatePopupPosition = (map, popup, line) => {
  const center = map.getCenter();
  const nearestPoint = nearestPointOnLine(
    line,
    point([center.lng, center.lat])
  );

  // Determine if both AoIs are visible and set the popup at the midpoint if so
  const bounds = map.getBounds();
  const startPoint = point(line.geometry.coordinates[0]);
  const endPoint = point(
    line.geometry.coordinates[line.geometry.coordinates.length - 1]
  );

  if (
    bounds.contains(startPoint.geometry.coordinates) &&
    bounds.contains(endPoint.geometry.coordinates)
  ) {
    const midPoint = midpoint(startPoint, endPoint);
    popup.setLngLat(midPoint.geometry.coordinates);
  } else {
    popup.setLngLat(nearestPoint.geometry.coordinates);
  }
};

/**
 * Handles map movement and updates the popup's position and size accordingly.
 * @param {object} map - The map instance.
 * @param {object} popup - The popup instance to update.
 * @param {object} line - The GeoJSON line feature.
 */
const handleMapMove = (map, popup, line) => {
  map.on("move", () => {
    updatePopupPosition(map, popup, line);
  });

  map.on("zoomend", () => {
    const zoom = map.getZoom();
    updatePopupSize(popup.getElement(), zoom);
    updatePopupPosition(map, popup, line);
  });
};

/**
 * Adjusts the start and end points of a line feature.
 * @param {object} line - The GeoJSON line feature.
 * @param {number} distance - The distance for the offset at the start and end of the line.
 * @returns {object} The adjusted GeoJSON line feature.
 */
const adjustLineEndpoints = (line, distance) => {
  const lineLength = line.geometry.coordinates.length;
  const startOffset = along(lineString(line.geometry.coordinates), distance);
  const endOffset = along(
    lineString(line.geometry.coordinates),
    lineLength - (distance + 1)
  );
  const offsetCoordinates = line.geometry.coordinates.slice(1, lineLength - 1);
  return {
    type: "Feature",
    geometry: {
      type: "LineString",
      coordinates: [
        startOffset.geometry.coordinates,
        endOffset.geometry.coordinates,
      ],
    },
  };
};

/**
 * Renders the links on the map using GeoJSON data.
 * @param {object} map - The map instance.
 */
export const renderLinks = (map) => {
  LINK_GEOJSON.forEach((item, index) => {
    const coordinates = item?.data.geometry.coordinates;
    if (!coordinates || coordinates.length < 2) return;

    // Remove previous layers before rendering new ones
    removePrevLayer(map, index, "symbols", "route", "route");

    // Create a GeoJSON line feature
    const line = {
      type: "Feature",
      geometry: {
        type: "LineString",
        coordinates,
      },
    };
    // Smooth the line using bezierSpline
    const curvedLine = bezierSpline(line, { sharpness: 0.1 });

    // Adjust the line endpoints for the original route
    const adjustedLine = adjustLineEndpoints(curvedLine, 0.04);

    // Render the route layer on the map
    renderRouteLayer(map, adjustedLine, index);

    // Adjust the line endpoints for the cloned route
    const clonedLine = adjustLineEndpoints(curvedLine, 0.0);
    renderRouteLayer(map, clonedLine, `${index}-clone`);

    // Render the popup at the initial coordinate on the map
    const initialCoordinate = nearestPointOnLine(
      adjustedLine,
      point([map.getCenter().lng, map.getCenter().lat])
    ).geometry.coordinates;
    const popup = renderPopup(map, initialCoordinate, index);

    // Handle map movement and update the popup position and size
    handleMapMove(map, popup, adjustedLine);
  });

  // Move the layers to ensure proper rendering order
  moveLayers(map);
};
