import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "react-bootstrap";
import AppRoutes from "./routes/AppRoutes";
function App() {
  return (
    <ThemeProvider
      breakpoints={["xxxl", "xxl", "xl", "lg", "md", "sm", "xs", "xxs"]}
      minBreakpoint="xxs"
    >
      <BrowserRouter>
        <AppRoutes></AppRoutes>
      </BrowserRouter>
    </ThemeProvider>
  );
}
export default App;
