import { useState, useEffect } from "react";
import { LINK_GEOJSON_COORDINATES } from "../../constants/geojsondump";

/**
 * LineStringPopup Component
 *
 * This component displays a popup with dynamic content based on the map's zoom level.
 * It listens to the map's `zoomend` event to update the zoom level in real-time.
 *
 * Props:
 * - map (Object): A map instance that supports the `on` and `off` event methods and `getZoom` function.
 */
const LineStringPopup = ({ map, coordinate, index }) => {
  // State to track the current zoom level of the map
  const [zoom, setZoom] = useState(1);
  let currentIndex = 0;

  /**
   * Effect: Adds an event listener to update zoom level on `zoomend` event.
   * Cleans up the event listener when the component unmounts.
   */
  useEffect(() => {
    const handleZoomEnd = () => setZoom(map?.getZoom());

    // Attach the event listener
    map.on("zoomend", handleZoomEnd);

    // Cleanup the event listener on component unmount
    return () => {
      map.off("zoomend", handleZoomEnd);
    };
  }, [map]);
 
  const handleClick = (event) => {
    if (map) {
        // Toggle index between 0 and 1
        currentIndex = currentIndex === 0 ? 1 : 0;
    
        map.flyTo({
          center: LINK_GEOJSON_COORDINATES[currentIndex],
          essential: true, // This makes the animation last even if the user interrupts it
          speed: 1.2, // Optional: Faster speed for zoom-in
        });
      }
  };

  /**
   * Renders the content conditionally based on the zoom level.
   *
   * @returns {JSX.Element} Content to be displayed in the popup.
   */
  const renderContent = () => {
    return (
      <ul
        style={{ listStyleType: "none", padding: 0, margin: 0 }}
        onClick={handleClick}
      >
        <li>50kg</li>
        <li>R5000</li>
        <li>5250</li>
      </ul>
    );
  };
  // const renderContent = () => {
  //     if (zoom > 12) {
  //         return (
  //             <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
  //                 <li>50kg</li>
  //                 <li>R5000</li>
  //                 <li>5250</li>
  //             </ul>
  //         );
  //     }
  //     return (
  //         <p style={{ marginRight: "0.5rem", color: "black", textDecoration: "underline" }}>
  //             AOI Details
  //         </p>
  //     );
  // };

  /**
   * Component JSX structure:
   * - A header displaying "PRO" with an icon.
   * - Conditional content based on the zoom level.
   */
  return (
    <div
      style={{
        borderRadius: "8px",
        // width: zoom > 17 ? "300px" : "150px", // Adjust width based on zoom level
        // minHeight: zoom > 17 ? "150px" : "50px", // Adjust height based on zoom level
        transition: "all 0.3s ease-in-out", // Smooth transition for size changes
      }}
    >
      <div className="d-flex justify-content-between">
        <p
          style={{
            marginRight: "1rem",
            marginBottom: 0,
            color: "black",
            textDecoration: "underline",
          }}
        >
          BBC
        </p>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="22"
          fill="currentColor"
          className="bi bi-arrow-up-right-circle"
          viewBox="0 0 16 16"
          style={{ transform: "rotate(-65deg)" }} /* Rotates to 11 o'clock */
        >
          <path
            fillRule="evenodd"
            d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.854 10.803a.5.5 0 1 1-.708-.707L9.243 6H6.475a.5.5 0 1 1 0-1h3.975a.5.5 0 0 1 .5.5v3.975a.5.5 0 1 1-1 0V6.707z"
          />
        </svg>
      </div>
      {renderContent()}
    </div>
  );
};

export default LineStringPopup;
