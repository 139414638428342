
import { removePopups } from "./removeSources";

/**
 * This function is responsible in creating island view when clicked on a certain markers, which is adding opacity to the background, changing outline of AOI to yellow and removing text label layers
 * @param {object} map 
 * @param {array} allLayers 
 */
export function addLayersFocusedView(map,allLayers) {
    // map.setPaintProperty('land', 'background-color', [
    //     "interpolate",
    //     [
    //       "linear"
    //     ],
    //     [
    //       "zoom"
    //     ],
    //     9,
    //     "hsla(207, 0%, 100%, 0.5)",
    //     11,
    //     "hsla(207, 0%, 15%, 0.5)"]
    //   );
  
      allLayers?.forEach((layer) => {
        if (layer.id.includes('label')) {
          map.setLayoutProperty(
            layer.id,
            'visibility',
            'none'
          );
        }
      });
      allLayers?.forEach((layer) => {
        if (layer.id.startsWith("line-excluded-polygon")) {
          map.setPaintProperty(layer.id, 'line-color', '#fce89d');
        }
      });
   
}
/**
 * This function is responsible in reverting from island view when clicked on a certain markers, which is adding opacity to the background, changing outline of AOI to white and adding back text label layers
 * @param {object} map 
 * @param {array} allLayers 
 */
export function removeLayersUnfocusedView(map, allLayers) {
    removePopups(map)
    allLayers?.forEach((layer) => {
        if (layer.id.startsWith("route")) {
            map?.removeLayer(layer.id);
        }
    });
    allLayers?.forEach((layer) => {
        if (layer.id.includes('label')) {
            map.setLayoutProperty(
                layer.id,
                'visibility',
                'visible'
            );
        }
        if (layer?.id?.startsWith("line-excluded-polygon")) {
            map.setPaintProperty(layer?.id, 'line-color', '#ffffff');
          }
    });
    // map.setPaintProperty('land', 'background-color', [
    //     "interpolate",
    //     [
    //       "linear"
    //     ],
    //     [
    //       "zoom"
    //     ],
    //     9,
    //     "hsl(207, 0%, 100%)",
    //     11,
    //     "hsl(207, 0%, 15%)"]
    //   );
   
}