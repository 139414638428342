export function spinGlobe(map) {
    const maxSpinZoom = 3;
    if (
      map?.getZoom() < maxSpinZoom &&
      map?.getProjection()?.name === "globe"
    ) {
      let distancePerSecond = 360 / 120;
      const center = map.getCenter();
      center.lng -= distancePerSecond;
      // Smoothly animate the map over one second.
      // When this animation is complete, it calls a 'moveend' event.
      map.easeTo({ center, duration: 1000, easing: (n) => n });
    }
  }
