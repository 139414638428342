import React, { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Supercluster from "supercluster";
import { mapboxgl } from "../../configs/mapbox";
import { MAP, LAYERS, SUPER_CLUSTER } from "../../constants";
import MapContext from "../../contexts/mapContext";
import { spinGlobe } from "../../utils/spinGlobe";
import "mapbox-gl/dist/mapbox-gl.css";
import "../MapProvider/index.css";
// Mapbox configurations for start of map renderring.
const start = {
  center: [MAP.centerLng, MAP.centerLat],
  zoom: MAP.initialZoom,
  pitch: 0,
  bearing: 0,
  maxZoom: MAP.finalZoom,
};
// Mapbox configurations for easeTo of map animation.
const original = {
  zoom: 1,
};
const end = {
  center: [MAP.centerLng, MAP.centerLat],
  zoom: MAP.initialZoom,
  maxZoom: MAP.finalZoom,
};


/**
 * Map Provider Component for handling mapbox initialization, theming , rendering and managing map's context throughout
 * the application.
 * @param {JSXElement} children children props to render dynamic content that cannot be determined at design time 
 * @returns 
 */
const MapProvider = ({ children }) => {
  const mapRef = useRef(null);
  const superCluster = useRef(null);
  const [isMapReady, setIsMapReady] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const mapContainerRef = useRef(null);
  const [timeoutId, setTimeoutId] = useState(null);
  const [zoom, setZoom] = useState(MAP.initialZoom);
  const auth = useSelector((state) => state.auth.isAuthenticated);
  /**
   * Side effect To create instance of map
   */
  useEffect(() => {
    /**
       * Initial map configuration
       * 
       * container:  requires HTML element in which Mapbox renders the map, hence ref to access the map instance.
       * style: Map's initial style, dark theme is set initially
       * projection: Mapbox has two map options "Mercator" or "Globe". We are using globe for initial style before authentication.
       * center: Coordinates where map should be zoomed in
       * zoom: initial zoom value for map view.
       * preserveDrawingBuffer: if true , the map's canvas can be exported to a PNG 
       * 
       */
    if (mapRef.current) return; //initialize map only once
    if (!mapboxgl.supported()) {
      alert("Your browser does not support Mapbox GL");
    } else {
      setIsLoading(true);
      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: LAYERS?.SETALLITEV9,
        projection: MAP.projection.globe,
        center: [MAP.centerLng, MAP.centerLat],
        zoom: MAP.initialZoom,
        ...start,
        preserveDrawingBuffer: true,
      });
      // mapRef.current = map;
      setIsMapReady(true);
      mapRef.current.on("move", () => {
        /**
         * A move event occurs when the user drags a single finger across the screen causing the 
         * camera position to change, hence on each single click increase soom level by two.
         */
        setZoom(mapRef?.current?.getZoom().toFixed(2));
      });
      mapRef.current.on("load", () => {
        /**
        * After the map has been loaded and user authorized, add navigation controls and initialize
        * supercluster for marker clusters
        */
        setIsLoading(false);
        setIsMapReady(false)
        spinGlobe(mapRef.current);

        if (auth) {
          if (
            document.querySelector(
              " .mapboxgl-ctrl-group .mapboxgl-ctrl-compass"
            ) === null
          ) {
            console.log(document.querySelector(
              " .mapboxgl-ctrl-group .mapboxgl-ctrl-compass"
            ) )
            mapRef.current.addControl(
              new mapboxgl.NavigationControl(),
              "bottom-right"
            );
          }
        }

        /**
         * Utilizing supercluster library for geospatial point clustering 
         */
        let supercluster = new Supercluster({
          radius: SUPER_CLUSTER.radius,
          minZoom: SUPER_CLUSTER.minZoom, 
          maxZoom: SUPER_CLUSTER.maxZoom, 
          minPoints: SUPER_CLUSTER.minPoints,
          extent: SUPER_CLUSTER.extent,
          nodeSize: SUPER_CLUSTER.nodeSize
        });
        mapRef.current.on('click', 'clusters', (e) => {
          console.log(">>>")
          // const features = map.queryRenderedFeatures(e.point, {
          //   layers: ['clusters'],
          // });
  
          // if (!features.length) return;
  
          // const clusterId = features[0].properties.cluster_id;
  
          // if (supercluster) {
          //   const zoom = map.getZoom();
          //   const expansionZoom = supercluster.getClusterExpansionZoom(clusterId);
          //   const targetZoom = zoom + (expansionZoom - zoom) * 0.5; // Custom zoom jump, change 0.5 to control zoom level
  
          //   map.easeTo({
          //     center: features[0].geometry.coordinates,
          //     zoom: targetZoom,
          //   });
          // }
        });
      
        superCluster.current = supercluster;
      });
      mapRef.current.on("moveend", () => {
        spinGlobe(mapRef.current);
      });
    }

    return () => {
      //Initialize map only once
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }

    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (auth) {
      const newTimeoutId = setTimeout(() => {
        if (auth) {
          try {
            mapRef.current?.doubleClickZoom.enable();
            mapRef.current?.scrollZoom.enable();
            mapRef.current?.dragRotate.enable();
            mapRef.current?.dragPan.enable();
            mapRef.current?.setProjection("mercator");
          } catch (error) {
            console.error("Network error:", error);
          }
        }
      }, 8000);
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      setTimeoutId(newTimeoutId);
      let target = end;
      mapRef.current?.flyTo({
        ...target,
        duration: 8000,
        essential: true,
      });
    } else if (!auth) {
      superCluster?.current?.load([]);
      mapRef.current._markers = [];
      spinGlobe(mapRef.current);
      try {
        mapRef.current.easeTo({
          ...original,
          duration: 4000,
          speed: 0.2,
          essential: true,
          easing(t) {
            return t;
          },
        });
        mapRef.current?.setProjection("globe");
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
        mapRef.current?.doubleClickZoom.disable();
        mapRef.current?.scrollZoom.disable();
        mapRef.current?.dragRotate.disable();
        mapRef.current?.dragPan.disable();
      } catch (err) {
        console.log(err);
      }
      const newTimeoutId = setTimeout(() => {
        try {
          mapRef.current?.setStyle(LAYERS.DARK1);
          spinGlobe(mapRef.current);
        } catch (error) {
          console.error("Network error:", error);
        }
      }, 4000);
      setTimeoutId(newTimeoutId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);
  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);


  return (
    <MapContext.Provider
      // Map Context Provider serves as a way to share data or state to all the descendant components in the component tree.
      value={{
        mapRef,
        mapContainerRef,
        isMapReady,
        superCluster,
        isLoading,
        zoom

      }}
    >
      {children}
    </MapContext.Provider>
  );
};

export default MapProvider;
