import { Route, Routes } from "react-router-dom";
import MapProvider from "../components/MapProvider";
import MainPage from "../pages/MainPage";
import { NAVIGATION_ROUTE } from "../constants";

const AppRoutes = () => {
  return (
    <MapProvider>
    <Routes>
      {/* Main page '/' is managing all components, including parent component 'MainPage' */}
      <Route path={NAVIGATION_ROUTE.main} element={<MainPage />}></Route>
      {/* <Route
        path={NAVIGATION_ROUTE.homepage}
        element={
          <ProtectedRoutes>
            <HomePage></HomePage>
          </ProtectedRoutes>
        }
      ></Route> */}
    </Routes>
    </MapProvider>
  );
};

export default AppRoutes;
