import { MAP } from "../constants";

/**
 * Adds a GeoJSON source to the map if it doesn't already exist.
 * @param {object} map - The map object.
 * @param {string} sourceId - The unique identifier for the source.
 * @param {object} data - GeoJSON data for the source.
 */
export function addGeojsonSource(map, sourceId, data) {
  if (!map.getSource(sourceId)) {
    map.addSource(sourceId, {
      type: "geojson",
      data: data,
    });
  }
}

/**
 * Adds a raster source to the map if it doesn't already exist.
 * @param {object} map - The map object.
 * @param {string} sourceId - The unique identifier for the source.
 * @param {object} data - GeoJSON data for the source.
 * @param {Array} bounds - Bounding box for the raster source.
 */
export function addRasterSource(map, sourceId, data, bounds) {
  if (!map.getSource(sourceId)) {
    map.addSource(sourceId, {
      type: "raster",
      tiles: [
        `https://www.fused.io/server/v1/realtime-shared/fsh_GlFJMkzcKXH9CO6Z964qM/run/tiles/{z}/{x}/{y}?dtype_out_vector=mvt&target_gdf=${encodeURIComponent(
          JSON.stringify(data)
        )}`,
      ],
      minzoom: 4,
      maxzoom: MAP.finalZoom,
      bounds: bounds,
    });
  }
}

/**
 * Adds a fill layer to the map if it doesn't already exist, with a fade-in effect.
 * @param {object} map - The map object.
 * @param {string} layerId - The unique identifier for the layer.
 * @param {string} sourceId - The unique identifier for the source.
 * @param {string|null} fillColor - Color for the fill layer.
 * @param {number} fillOpacity - Opacity for the fill layer.
 */
export function addFillLayer(map, layerId, sourceId, fillColor = "#000", fillOpacity = 0.05) {
    fillColor = fillColor? fillColor : "#FFF";
  if (!map.getLayer(layerId)) {
    map.addLayer({
      id: layerId,
      type: "fill",
      source: sourceId,
      layout: {},
      paint: {
        "fill-color": fillColor,
        "fill-opacity": 0,
      },
    });
    // Fade-in effect
    map.setPaintProperty(layerId, "fill-opacity", fillOpacity);
  }
}

/**
 * Adds a line layer to the map if it doesn't already exist, with a fade-in effect.
 * @param {object} map - The map object.
 * @param {string} layerId - The unique identifier for the layer.
 * @param {string} sourceId - The unique identifier for the source.
 * @param {string} lineColor - Color for the line layer.
 * @param {number} lineWidth - Width of the line.
 */
export function addLineLayer(map, layerId, sourceId, lineColor, lineWidth = 2) {
  if (!map.getLayer(layerId)) {
    map.addLayer({
      id: layerId,
      type: "line",
      source: sourceId,
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": lineColor,
        "line-width": lineWidth,
        "line-opacity": 0,
      },
    });
    // Fade-in effect
    map.setPaintProperty(layerId, "line-opacity", 1);
  }
}

/**
 * Removes a layer from the map with a fade-out effect, if it exists.
 * @param {object} map - The map object.
 * @param {string} layerId - The unique identifier for the layer.
 */
export function removeLayerIfExists(map, layerId) {
  if (map.getLayer(layerId)) {
    map.removeLayer(layerId);
  }
}

/**
 * Removes a source from the map if it exists.
 * @param {object} map - The map object.
 * @param {string} sourceId - The unique identifier for the source.
 */
export function removeSourceIfExists(map, sourceId) {
  if (map.getSource(sourceId)) {
    map.removeSource(sourceId);
  }
}
