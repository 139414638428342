/**
 * Geo-json data for segments implementation, it's used a s a query param in udf for Area of Interest
 */
export const GEOJSON_ENCODED = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.437, 13.79],
            [-89.43, 13.792],
            [-89.425, 13.796],
            [-89.42, 13.8],
            [-89.41, 13.805],
            [-89.405, 13.81],
            [-89.4, 13.815],
            [-89.395, 13.82],
            [-89.385, 13.825],
            [-89.38, 13.83],
            [-89.375, 13.832],
            [-89.365, 13.832],
            [-89.36, 13.825],
            [-89.365, 13.815],
            [-89.37, 13.805],
            [-89.375, 13.795],
            [-89.38, 13.79],
            [-89.387, 13.788],
            [-89.392, 13.786],
            [-89.437, 13.79],
          ],
        ],
      },
    },
  ],
};
export const LINK_GEOJSON_COORDINATES = [
  [18.558063449224107, -33.937021413567344],
  [18.606205474476695, -34.066195234314094],
];

export const LINK_GEOJSON = [
  {
    type: "geojson",
    data: {
      type: "Feature",
      properties: {
        title: "Linked AOI",
        "image-name": "bgCoords",
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [18.558063449224107, -33.937021413567344],
          [18.606205474476695, -34.066195234314094],
        ],
      },
    },
  },
  {
    type: "geojson",
    data: {
      type: "Feature",
      properties: {
        title: "Linked AOI",
        "image-name": "bgCoords",
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [18.558063449224107, -33.937021413567344],
          [18.606205474476695, -34.066195234314094],
        ],
      },
    },
  },
];
export const RASTER_GEOJSON = [
  {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        properties: {},
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [18.6061301381554, -34.066215100759976],
              [18.606283694621503, -34.06637341221306],
              [18.606354773160398, -34.066346193773754],
              [18.6062649191584, -34.06616844048578],
              [18.6061301381554, -34.066215100759976],
            ],
          ],
        },
      },
    ],
  },
  {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        properties: {},
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [18.55790730929924, -33.936706526449115],
              [18.55789658046318, -33.9368812129143],
              [18.5579006037767, -33.93691236715069],
              [18.55791133261276, -33.93693128221722],
              [18.557930108075865, -33.93695464788185],
              [18.557956930166014, -33.93697467558927],
              [18.55798107004715, -33.93699136534186],
              [18.558009233241805, -33.93700471714159],
              [18.55803873754097, -33.93701361834024],
              [18.558062877422103, -33.93701806893921],
              [18.55863687015129, -33.937038096631724],
              [18.55864625788284, -33.93682112971193],
              [18.558561768298873, -33.936631978612816],
              [18.55855774498535, -33.936613063479804],
              [18.55855774498535, -33.93659303568731],
              [18.55856847382141, -33.936361603076854],
              [18.55735611534668, -33.93633044863895],
              [18.55734538651062, -33.93668761133264],
              [18.55790730929924, -33.936706526449115],
            ],
          ],
        },
      },
    ],
  },
];

// Calculate the bounding box for the entire world (outer boundary)
export const WORLD_BOUNDING_BOX = {
  type: "Feature",
  geometry: {
    type: "Polygon",
    coordinates: [
      [
        [-180, -90], // Southwest corner
        [180, -90], // Southeast corner
        [180, 90], // Northeast corner
        [-180, 90], // Northwest corner
        [-180, -90], // Close the loop
      ],
    ],
  },
};
