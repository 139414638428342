import React, { useState } from "react";
import moment from "moment";
import { Form } from "react-bootstrap";

import { IMG_MODES, ROLES } from "../../constants";
import "./index.css";
import ModalComponent from "../ModalComponent";

/**
 * Header Component for DateRange, Captured Image modes , Markers and Segments filters.
 * @Component
 * @param {object} props The component accepts startDate, endDate, role, setStartDate, setEndDate, selectedImageMode, setCurrentMarkers, setCurrentSegments, setImageFilterMarkers
 * @param {string} props.startDate The start date from date filter
 * @param {string} props.endDate The end date from date filter
 * @param {string} props.role The role type either admin or user
 * @param {function} props.setStartDate The setter function for start date
 * @param {function} props.setEndDate The setter function for end date
 * @param {function} props.selectedImageMode The setter function for image mode
 * @param {function} props.setCurrentMarkers The setter function for current markers
 * @param {function} props.setCurrentSegments The settter functio for current segments
 * @param {function} props.setImageFilterMarkers The setter function for image filter
 * @returns {JSX.Element} Returns the rendered Header component currently visible for admin only.
 */

function Header(props) {
  const [modalShow, setModalShow] = useState(false);
  return (
    <div className="header">
      <div onClick={() => setModalShow(true)}></div>
      {/* Filters Form that is rendered for admin role only. */}
      {props.role === ROLES.ADMIN && (
        <>
          {/* Form control to get date inputs as range */}
          <Form.Control
            onClick={() => setModalShow(true)}
            type="text"
            readOnly
            value={`Date Range : ${
              props.startDate && moment(props.startDate).format("DD-MM-YYYY")
            } - ${props.endDate && moment(props.endDate).format("DD-MM-YYYY")}`}
            className="date-input"
          />
          {/* Form control to select image mode */}
          <Form.Select
            onChange={(e) => props.setSelectedImageMode(e.target.value)}
            className="mode-input"
            value={props.selectedImageMode}
          >
            <option
              value=""
              style={{ fontFamily: "Montserrat", fontSize: "1.1vw" }}
            >
              All Modes
            </option>
            <option
              value={IMG_MODES.waypoint}
              style={{ fontFamily: "Montserrat", fontSize: "1.1vw" }}
            >
              Waypoint
            </option>
            <option
              value={IMG_MODES.snapshot}
              style={{ fontFamily: "Montserrat", fontSize: "1.1vw" }}
            >
              Snapshot
            </option>
            <option
              value={IMG_MODES.panorama}
              style={{ fontFamily: "Montserrat", fontSize: "1.1vw" }}
            >
              Panorama
            </option>
          </Form.Select>
          {/* Form control to select client visibility for markers */}
          <Form.Select
            onChange={(event) => {
              props.setCurrentMarkers(event.target.value);
            }}
            className="mode-input"
            value={props.currentMarkers}
          >
            <option
              value=""
              style={{ fontFamily: "Montserrat", fontSize: "1.1vw" }}
            >
              All Markers
            </option>
            <option
              value="client-markers"
              style={{ fontFamily: "Montserrat", fontSize: "1.1vw" }}
            >
              Client Markers
            </option>
          </Form.Select>
          {/* Form control to select client visibility for segments */}
          <Form.Select
            onChange={(event) => {
              props.setCurrentSegments(event.target.value);
            }}
            className="mode-input2"
            value={props.currentSegments}
          >
            <option
              value=""
              style={{ fontFamily: "Montserrat", fontSize: "1.1vw" }}
            >
              All Segments
            </option>
            <option
              value="client-segments"
              style={{ fontFamily: "Montserrat", fontSize: "1.1vw" }}
            >
              Client Segments
            </option>
          </Form.Select>
        </>
      )}
      {/* Date range selection modal, rendered in response of selectDateRange onClick fun. in header form.  */}
      <ModalComponent
        modalShow={modalShow}
        setModalShow={setModalShow}
        setStartDate={props.setStartDate}
        setEndDate={props.setEndDate}
        startDate={props.startDate}
        endDate={props.endDate}
      ></ModalComponent>
    </div>
  );
}

export default Header;
