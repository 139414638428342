import { configureStore } from '@reduxjs/toolkit';
import {
    persistStore,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
  } from 'redux-persist';
import rootReducer from './reducer';
/**
 * Using Redux Persist to save the state in persistent storage
 * so that even after a refresh, the data will remain intact.
 */
export const store = configureStore({
  reducer: rootReducer, //rootReducer is an enhanced reducer with a configuration to persist the authReducer and capturedImagesReducer state to local storage
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
    }),
  devTools: process.env.REACT_APP_APP_MODE !== 'production'
})

export const persistedStore = persistStore(store);