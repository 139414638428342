import { IMG_MODES } from "../../constants";
/**
 * This component handles creating custom html elements for each marker .
 * @param {*} props
 * @param {object} props.markerData Marker data object
 * @param {array} props.uniqueMarkerData Array of markers categorized uniquely by their coordinates.
 * @param {double} props.rotation  Markers' rotation value ( heading / direction)
 * @param {function} props.setClickedMarker Setter for marker data object of selected object
 * @param {function} props.setImgGallery Setter for opening image gallery
 * @returns {JSX.Element} Returns the rendered marker element.
 */
const Marker = ({
  markerData,
  uniqueMarkerData,
  rotation,
  setClickedMarker,
  setImgGallery,
}) => {
  /**
   * This function handles opening image gallery on markers's click and setting state for clicked marker.
   */
  // uniqueMarkerData[markerData.location] && uniqueMarkerData[markerData.location]?.length > 1 && console.log(rotation,uniqueMarkerData[markerData?.location]?.length)
  const onMarkerClick = () => {
    setClickedMarker(markerData);
    setImgGallery(true);
  };
  return (
    <div
      onClick={onMarkerClick}
      id={markerData?.id}
      data-html2canvas-ignore="true"
    >
      {/* Marker styling, cluster styles in case of more than one unique markers and 
      indivisual markers style in case of a single unique marker */}
      {uniqueMarkerData[markerData.location] && uniqueMarkerData[markerData.location]?.length > 1 ? (
        
        <div
          style={{ position: "relative", display: "flex" }}
          heading={
            markerData.capturedImageMode === IMG_MODES.panorama &&
            markerData?.panoramaImages?.length > 1
              ? markerData?.panoramaImages[1]?.heading
                ? markerData?.panoramaImages[1]?.heading
                : 0
              : markerData.heading
              ? markerData.heading
              : 0
          }
        >
          {/* Cluster markers styling */}
          <p
            className="cluster-text"
            style={{
              lineHeight: 1,
              fontSize: "14px",
              margin: "auto",
              transform: `rotate(${360 - rotation}deg)`,
              paddingRight: ` ${
                (uniqueMarkerData[markerData?.location]?.length > 11 &&
                  uniqueMarkerData[markerData?.location]?.length < 20) ||
                uniqueMarkerData[markerData?.location]?.length === 10
                  ? "1px"
                  : "0px"
              }`,
              paddingLeft: `${
                uniqueMarkerData[markerData?.location]?.length % 10 === 1 &&
                uniqueMarkerData[markerData?.location]?.length !== 11
                  ? "1px"
                  : "0px"
              }`,
            }}
          >
            {uniqueMarkerData[markerData?.location]?.length}
          </p>
        </div>
      ) : uniqueMarkerData[markerData.location] &&
        uniqueMarkerData[markerData.location].length <= 1 ? (
        <div
          className={
            markerData.capturedImageMode === IMG_MODES.panorama
              ? "yellow-marker"
              : markerData.capturedImageMode === IMG_MODES.waypoint
              ? "white-marker"
              : "purple-marker"
          }
        ></div>
      ) : (
        <div className="empty-marker"></div>
      )}
    </div>
  );
};
export default Marker;
