/**
   * This function creates a custom cluster element with the count of markers.
   * @param {int} count count of markers in the specific cluster
   * @param {int} cluster cluster id
   * @param {object} geometry geometry object containing coordinates (lat/long) for cluster
   * @returns Returns a cluster element for given markers.
   */
export function createClusterElement(
    map,
    markersCount,
    cluster,
    geometry,
  ) {
    var element = document.createElement("div");
    element.className = "cluster-marker";
    element.id = cluster;
    element.innerHTML = markersCount;
    element.setAttribute("coordinates", JSON.stringify(geometry.coordinates));
    element.setAttribute("data-html2canvas-ignore", "true");
    element.addEventListener("click", (e) => {
      const customValue = e.target.getAttribute("coordinates");
      const parsedCoord = JSON.parse(customValue);
      map.current?.flyTo({
        center: parsedCoord,
        zoom:
          map.current.getZoom() < 20
            ? map.current.getZoom() + 4
            : map.current.getZoom() + 1,
        essential: true,
      });
      // if(map.current.zoom()>=20 )
    });
    return element;
  }