import React from "react";
import { createRoot } from "react-dom/client";

import Marker from "../components/MarkerComponent";
import { updateClusters } from "./updateClusters";
import { mapboxgl } from "../configs/mapbox";
import { IMG_MODES } from "../constants";

 /**
   * This function handles getting fetched marker data and the rendering html elements as White markers
   * for each of the marker data,
   * @param {array} markersData
   * @param {array} fetchedData
   */
export function renderMarkers(
  markersData,
  fetchedData,
  uniqueMarkerData,
  markersRef,
  copiedMarkerRef,
  superCluster,
  mapRef,
  setUniqueMarkerData,
  setClickedMarker,
  setImgGallery
) {
  if (fetchedData) {
    for (const markerData of fetchedData) {
      if(markerData && markerData.location){
        const ref = React.createRef();
        ref.current = document.createElement("div");
        let rotation =
          markerData.capturedImageMode === "panorama" &&
          markerData?.panoramaImages?.length > 1
            ? markerData?.panoramaImages[1]?.heading
            : markerData.heading
            ? markerData.heading
            : 0;
        // renders marker component created on root of the map ref's instance
        createRoot(ref.current).render(
          <Marker
            markerData={markerData}
            uniqueMarkerData={uniqueMarkerData}
            rotation={rotation}
            setImgGallery={setImgGallery}
            setClickedMarker={setClickedMarker}
          />
        );
        const location = JSON.parse(markerData.location);
        const coordinates = [location.longitude, location.latitude];
        ref.current.addEventListener("click", () => {
          setClickedMarker(markerData);
          setImgGallery(true);
        });
        let newMarker;
        if (
          markerData.capturedImageMode === IMG_MODES.panorama &&
          markerData?.panoramaImages?.length >= 1
        ) {
          newMarker = new mapboxgl.Marker(ref.current)
            .setLngLat(coordinates)
            .setRotation(
              markerData?.panoramaImages[1]?.heading
                ? markerData?.panoramaImages[1]?.heading
                : 0
            );
        } else {
          newMarker = new mapboxgl.Marker(ref.current)
            .setLngLat(coordinates)
            .setRotation(markerData.heading ? markerData.heading : 0);
        }
        newMarker.markerData = markerData;
        markersData.push(newMarker);
      }
    }
    markersRef.current = markersData;
    copiedMarkerRef.current = markersData.map(function (marker) {
      return new mapboxgl.Marker(marker.getElement())
        .setLngLat(marker.getLngLat())
        .setRotation(marker.getRotation());
    });
    superCluster?.current?.load(
      markersRef?.current?.map(function (marker, index) {
        return {
          geometry: {
            type: "Point",
            coordinates: marker?.getLngLat().toArray(),
          },
          properties: { marker_id: index, marker_data: marker.markerData },
        };
      })
    );
    //calls update cluster function to update cluster when camera positioning of map changes and fetched data changes.
    updateClusters(
      superCluster?.current,
      mapRef,
      markersRef,
      uniqueMarkerData,
      fetchedData,
      setUniqueMarkerData
    );
  }
}
