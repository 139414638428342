export function moveLayers(map) {
    const allLayers = map.getStyle().layers;
    const rasterLayers = allLayers.filter(layer => layer.id.startsWith('fused-raster-layer'));
    const routeLayers = allLayers.filter(layer => layer.id.startsWith('route-'));
    if (rasterLayers.length > 0) {
        const firstRasterLayerId = rasterLayers[0].id;
        routeLayers.forEach(routeLayer => {
            map.moveLayer(routeLayer.id, firstRasterLayerId);
        });
    }
}