import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { postData } from '../../api/apiClient';
import { API_END_POINT } from '../../constants';
const initialState = {
    isLoading: false,
    isSuccess: false,
    error: null,
    images:[]
  };
/**
 * This slice has asynchronous functions defined for managing captured images state. These will include fetch requests for caturedImages data and will handle sending that 
 * information to the frontend UI component.
 */
export const capturedImages = createAsyncThunk(
    'capturedImages',
    async (params, thunkApi) => {
        try {
            const response = await postData(`${API_END_POINT.capturedImages.getAll}`, params)
            if (response.status === 200) {
                const resData = response.data.capturedImages;
                return resData
            } else {
                throw new Error(response.error);
            }
        } catch (err) {
            const defaultErrResponse={
                errCode: err.response?.status,
                errMsg:'Something went wrong.'
            }
            const errResponse = err?.response?.data && (err?.response?.data.errMsg) ? err?.response?.data : defaultErrResponse
            return thunkApi.rejectWithValue(errResponse);
        }
    }
);

const capturedImagesSlice=createSlice({
    name:'capturedImages',
    initialState,
    reducers: {
        updateCapturedImages: (state, action) => {
            state.images=action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(capturedImages.pending, (state,action) => {
                state.isLoading = false;
                state.isLoading = true;
                state.error = null;

            })
            .addCase(capturedImages.fulfilled, (state, action) => {
                state.isSuccess = true;
                state.isLoading = false;
                state.error = null;
                state.images=action.payload
            })
            .addCase(capturedImages.rejected, (state, action) => {
                state.isLoading = false;
                state.isSuccess = false;
                state.error = action.payload;
                state.images=[]
                
            });
    
    },
})
export const selectcapturedImages = (state) => state.capturedImages;
export const { updateCapturedImages } = capturedImagesSlice.actions;
export default capturedImagesSlice.reducer