import React, { useState, useEffect } from 'react';
/**
 * This component manges dynamically importing SVGs andrendering icons
 * @param {string} props.id id for the icon element in css styles
 * @param {string} props.name svg icon's name in icons directory
 * @param {string} props.alt alternate text for image svg icon
 * 
 * @returns {JSXElement} returns rendered svg icon 
 */
const Icon = ({ name, id,alt}) => {
    const [icon, setIcon] = useState(null);
    useEffect(() => {
      const loadIcon = async () => {
        const module = await import(`../../assets/icons/${name}.svg`);
        setIcon(() => module.default);
      };
      loadIcon();
    }, [name]);
    return (
      < >
        {icon ? <img id={id} src={icon} alt={alt} /> : <p>Loading...</p>}
      </>
    );
  };
  
  export default Icon;
  