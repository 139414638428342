import { removePrevLayer } from "./removePrevLayer";

//Remove all polygons from map layer
export function removePolygons(map, allLayers) {
  allLayers?.forEach((layer) => {
    if (
      layer.id.startsWith("outlineLayer") ||
      layer.id.startsWith("regionLayer")  ||
      layer.id.startsWith("route")||
      layer.id.startsWith("symbols")
    ) {
      map?.removeLayer(layer.id);
    }
  });
  const sourcesToRemove = map?.getStyle().sources || {};
  Object.keys(sourcesToRemove).forEach((sourceId) => {
    if (sourceId.startsWith("source")|| sourceId.startsWith("route")) {
      map?.removeSource(sourceId);
    }
  });
}



/**
 * This function handles creating source layers for marker coordinates (segments) and rendering them on the map, based on
 * the geo json data categorized via coordinates value.
 * @param {string} role
 */

export function renderPolygons(map, coordinates) {
  coordinates.forEach((obj) => {
    const dataSource = {
      type: "geojson",
      data: {
        type: "Feature",
        properties: {
          id: obj.id,
        },
        geometry: {
          type: "Polygon",
          coordinates: [obj.coordinates],
        },
      },
    };

    // If layers already existed then remove previous layers
    removePrevLayer(map, obj.id, 'outlineLayer', 'regionlayer', 'sourceId')
    map.addSource(`sourceId-${obj.id}`, dataSource);
    map.addLayer({
      id: `regionLayer-${obj.id}`,
      type: "fill",
      source: `sourceId-${obj.id}`,
      layout: {},
      paint: {
        "fill-color": "#b565f7",
        "fill-opacity": 0.3,
      },
    });
    console.log('rendered')
    map.addLayer({
      id: `outlineLayer-${obj.id}`,
      type: "line",
      source: `sourceId-${obj.id}`,
      layout: {},
      paint: {
        "line-color": "#7DF9FF",
        "line-width": 3,
      },
    });
    map.on("mousemove", `regionLayer-${obj.id}`, (e) => {
      console.log(obj)
      map.setPaintProperty(
        `regionLayer-${obj.id}`,
        "fill-color",
        "#a1dab4"
      );
    });
    map.on("mouseleave", `regionLayer-${obj.id}`, (e) => {

      map.setPaintProperty(
        `regionLayer-${obj.id}`,
        "fill-color",
        "#6f65f7"
      );
    });


  });
}



